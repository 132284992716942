import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { isEmptyObject } from 'ui-auth-app/utils/helpers'
import { PasswordInput } from 'ui-lib/components/Input'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { Text, Label } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import checkboxCircle from 'ui-lib/icons/circle-checkmark.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PasswordValidationMessage } from '../components/passwordValidationMessage'

const Note = styled.div(p => ({
	...p.theme.fonts.medium,
	paddingBottom: '16px',
}))

const ResetPassword = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const { query = {} } = useSelector(AuthDuc.selectors.location)

	const { email, secret } = query

	const { status } = useSelector(AuthDuc.selectors.getResetPasswordState)
	const isSuccess = status === 'success'

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.min(12, t('validation.passwordLength'))
			.max(64, t('validation.passwordMaxLength'))
			.required(t('validation.passwordRequired')),
		repeatPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], t('validation.passwordMatch')),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			password: '',
			repeatPassword: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const formValues = {
				..._values,
				email,
				secret,
			}
			dispatch(
				AuthDuc.creators.resetPassword(formValues, { setSubmitting })
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.repeatPassword

	return (
		<Box
			justifyContent="center"
			alignItems="center"
			style={{ width: '100vw', height: '100vh', background: ' #1D285D' }}
		>
			{!isSuccess && (
				<Box
					style={{
						background: theme.color.white,
						borderRadius: 4,
					}}
					padding={isMobile ? 12 : 20}
					margin={isMobile ? 4 : 0}
				>
					<Box row justifyContent="flex-start">
						<ButtonWithNoBorder
							extendStyles={{
								fontWeight: 'bold',
								fontSize: theme.fontSize.xl,
								textDecoration: 'none',
								color: theme.color.blue5,
								padding: '0px',
							}}
							label={t('common.back')}
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.redirect(
										MainRouteDuc.types.AUTH,
										{
											action: 'forgot-password',
										}
									)
								)
							}}
						/>
					</Box>
					<Spacer size={30} />
					<Text
						style={{
							maxWidth: '500px',
							width: '100%',
							padding: '20px 0px',
							color: theme.color.accent2,
							lineHeight: '1.5em',
						}}
					>
						{t('login.resetPasswordNote')}
					</Text>
					<Spacer size={20} />
					<Box
						style={{
							width: '100%',
						}}
					>
						<form onSubmit={handleSubmit}>
							<Box
								style={{
									width: '100%',
								}}
							>
								<Label
									style={{
										fontWeight: 'bold',
										fontSize: theme.fontSize.xl,
										paddingBottom: '12px',
										color: theme.color.accent2,
									}}
								>
									{t('login.newPassword')}
								</Label>
								<PasswordInput
									name="password"
									value={values.password}
									type="password"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.password && errors.password}
									extendStyles={{
										background:
											values.password.length === 0
												? `${theme.color.grey11} 0% 0% no-repeat padding-box`
												: `${theme.color.white} 0% 0% no-repeat padding-box`,
										boxShadow:
											values.password.length === 0
												? '0px 6px 16px #05050707'
												: `0px 2px 8px ${theme.color.blue6}`,
										border:
											values.password.length === 0
												? `1px solid ${theme.color.grey4}`
												: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										opacity: 1,
									}}
								/>
								<Label
									style={{
										fontWeight: 'bold',
										fontSize: theme.fontSize.xl,
										paddingBottom: '12px',
										color: theme.color.accent2,
									}}
								>
									{t('login.confirmPassword')}
								</Label>
								<PasswordInput
									name="repeatPassword"
									value={values.repeatPassword}
									type="password"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched.repeatPassword &&
										errors.repeatPassword
									}
									extendStyles={{
										background:
											values.repeatPassword.length === 0
												? `${theme.color.grey11} 0% 0% no-repeat padding-box`
												: `${theme.color.white} 0% 0% no-repeat padding-box`,
										boxShadow:
											values.repeatPassword.length === 0
												? '0px 6px 16px #05050707'
												: `0px 2px 8px ${theme.color.blue6}`,
										border:
											values.repeatPassword.length === 0
												? `1px solid ${theme.color.grey4}`
												: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										opacity: 1,
									}}
								/>
								<PasswordValidationMessage
									password={values.password}
								/>
								<Button
									isLoading={isSubmitting}
									disabled={disableCTA}
									type="submit"
									primary
									label={t('login.resetPassword')}
								/>
							</Box>
						</form>
					</Box>
				</Box>
			)}
			<Spacer size={10} />
			{isSuccess && (
				<Box
					center
					style={{
						background: theme.color.white,
						borderRadius: 4,
					}}
					padding={isMobile ? 12 : 20}
					margin={isMobile ? 4 : 0}
				>
					<IconWrapper size={50} style={{ marginBottom: 30 }}>
						<Icon glyph={checkboxCircle} />
					</IconWrapper>
					<Note>{t('login.awesome')}</Note>
					<Note>{t('login.passwordChangeMsg')}</Note>

					<Box width={300}>
						<Button
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.redirect(
										MainRouteDuc.types.AUTH,
										{ action: 'sign-in' },
										{
											redirect_to: '/',
										}
									)
								)
							}
							primary
							label={t('login.done')}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default ResetPassword
