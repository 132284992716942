import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Text } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import Icon404 from 'ui-lib/icons/404.svg'

export const APIConfigError = () => {
	const { t } = useTranslation()

	return (
		<Box
			center
			style={{
				background: theme.color.grey0,
				width: '100vw',
				height: '100vh',
			}}
		>
			<IconWrapper style={{ width: 400, height: 400 }}>
				<Icon glyph={Icon404} />
			</IconWrapper>
			<Text
				style={{
					margin: '60px 0 16px',
					color: '#172B4D',
					fontSize: 32,
				}}
			>
				Uh Oh!
			</Text>
			<Text style={{ color: '#172B4D', fontSize: 32 }}>
				{t('common.apiConfigNotAvailable')}
			</Text>
		</Box>
	)
}
