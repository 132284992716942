import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { select } from 'redux-saga/effects'
import LogHelper from 'ui-auth-app/utils/logger'

const logger = LogHelper('ui-auth:AuthHelpers')

export function getAllowed(rules = [], allowed = [], denied = []) {
	return rules.reduce((agg, rule) => {
		const aggregator = agg

		const isAllowed = !denied.includes(rule) && allowed.includes(rule)

		aggregator[rule] = isAllowed

		return aggregator
	}, {})
}

export function isAtleastOneRuleActive(ruleMap = {}) {
	return Object.keys(ruleMap).some(_key => ruleMap[_key])
}

/**
 * React Hook helpers
 */

/** The hooks to use in react components */
export function useGetUserAllowedFeatures(rules = []) {
	const { allowed = [], denied = [] } = useSelector(
		AuthDuc.selectors.getCurrentUserRoles
	)

	return useMemo(() => getAllowed(rules, allowed, denied), [
		rules,
		allowed,
		denied,
	])
}

/** Passing the role map, gives back the flags toggling based on access
 *
 *  sample rolemap
 * 	const ROLE_ACCESSES = {
 *      canReadIncoming: 'fe.tdm.incoming.r',
 *      canReadOutgoing: 'fe.tdm.outgoing.r',
 *      canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
 *   }
 */
export function useGetUserAuthAccessFlags(rulesMap = {}) {
	const { allowed = [], denied = [] } = useSelector(
		AuthDuc.selectors.getCurrentUserRoles
	)

	return useMemo(
		() =>
			AuthDuc.options.helpers.getAllowedAccesses(
				allowed,
				denied,
				rulesMap
			),
		[allowed, denied, rulesMap]
	)
}

export function useFetchDataFilters(rule) {
	const { additionalMeta = {} } = useSelector(
		AuthDuc.selectors.getCurrentUserRoles
	)

	return useMemo(() => {
		return additionalMeta[rule] || {}
	}, [rule, additionalMeta])
}

/**
 * saga helpers to evaluate rules
 *
 * */

export function* getUserAllowedFeatures(rules = []) {
	try {
		const { allowed = [], denied = [] } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		return getAllowed(rules, allowed, denied)
	} catch (e) {
		logger.log(e)

		return []
	}
}

/** Passing the role map, gives back the flags toggling based on access
 *
 *  sample rolemap
 * 	const ROLE_ACCESSES = {
 *      canReadIncoming: 'fe.tdm.incoming.r',
 *      canReadOutgoing: 'fe.tdm.outgoing.r',
 *      canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
 *   }
 */
export function* getUserAuthAccessFlags(ruleMap = {}) {
	try {
		const { allowed = [], denied = [] } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		return AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ruleMap
		)
	} catch (e) {
		logger.log(e)

		return Object.keys(ruleMap).reduce((agg, rule) => {
			const aggregator = agg

			aggregator[rule] = false

			return aggregator
		})
	}
}

export function* checkIfAtleastOneFeatureAllowed(rules = []) {
	const _rules = yield getUserAllowedFeatures(rules)

	return isAtleastOneRuleActive(_rules)
}

export function* fetchDataFilters(rule) {
	try {
		const { additionalMeta = {} } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		return additionalMeta[rule] || {}
	} catch (e) {
		logger.log(e)

		return {}
	}
}
