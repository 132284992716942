import { getIn } from 'timm'
import { isMarketplaceHost } from 'ui-lib/utils/helpers'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_
export const getCurrentProject = () => lookUpConfig('REACT_APP_PROJECT') || ''

export const getIAMEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_IAM_URL'
			: 'REACT_APP_IAM_URL'
	) || ''}/`

export const getCoreEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_CORE_URL'
			: 'REACT_APP_CORE_URL'
	) || ''}/`

export const getInsightsEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_INSIGHTS_URL'
			: 'REACT_APP_INSIGHTS_URL'
	) || ''}/`

export const getNotifierEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_NOTIFIER_URL'
			: 'REACT_APP_NOTIFIER_URL'
	) || ''}/`

export const getInfoAddressLink = () =>
	lookUpConfig('REACT_APP_INFO_ADDRESS_LINK') || ''

export const getDibizAdminMail = () =>
	lookUpConfig('REACT_APP_DIBIZ_ADMIN_MAIL') || ''

export const featureFlags = {
	enableLoginCaptcha:
		lookUpConfig('REACT_APP_FTRS_LOGIN_CAPTCHA') !== 'false',
	enableSSO: lookUpConfig('REACT_APP_SSO') === 'true',
}

export const getLoginURL = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_LOGIN_HOST'
			: 'REACT_APP_LOGIN_HOST'
	) || ''}`

export const getSignupDetailURL = () =>
	lookUpConfig('REACT_APP_SIGNUP_DETAIL_URL') || ''

export const getseparatedTDMHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_TDM_HOST'
			: 'REACT_APP_TDM_HOST'
	) || ''}`

export const getRecaptchaKey = () =>
	lookUpConfig('REACT_APP_RECAPTCHA_KEY') || ''

export const getAppVersion = () => lookUpConfig('REACT_APP_VERSION') || ''

export const getGuidedTourStatus = () => lookUpConfig('REACT_APP_GUIDED_TOUR')

export const apiConfigAvailable =
	getIAMEndPoint().length > 1 && getCoreEndPoint().length > 1
