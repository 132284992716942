import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { getIn, removeLast } from 'timm'
import { getDateByFormat } from 'ui-auth-app/utils/date'

export const getOrganizationTypes = t => [
	{ name: 'palmoil-smallholder', label: t('invitePartner.smallholder') },
	{ name: 'palmoil-plantation', label: t('invitePartner.plantation') },
	{ name: 'palmoil-ffbDealer', label: t('invitePartner.ffb-dealer') },
	{ name: 'palmoil-ffbSubDealer', label: t('invitePartner.subDealer') },
	{ name: 'palmoil-mill', label: t('invitePartner.palm-oil-mill') },
	{
		name: 'palmoil-kernelMill',
		label: t('invitePartner.palm-kernel-oil-mill'),
	},
	{
		name: 'palmoil-refinery',
		label: t('invitePartner.palm-oil-refinery'),
	},
	{
		name: 'palmoil-oleochemicals',
		label: t('invitePartner.oleochemicals'),
	},
	{ name: 'palmoil-storage', label: t('invitePartner.storage-company') },
	{ name: 'palmoil-trader', label: t('invitePartner.trader') },
	{
		name: 'palmoil-endManufacturer',
		label: t('invitePartner.end-manufacturer'),
	},
]

export const orgTypesMapped = (t, type) => {
	const orgTypes = {
		'palmoil-smallholder': t('invitePartner.smallholder'),
		'palmoil-plantation': t('invitePartner.plantation'),
		'palmoil-ffbDealer': t('invitePartner.ffb-dealer'),
		'palmoil-ffbSubDealer': t('invitePartner.subDealer'),
		'palmoil-mill': t('invitePartner.palm-oil-mill'),
		'palmoil-kernelMill': t('invitePartner.palm-kernel-oil-mill'),
		'palmoil-refinery': t('invitePartner.palm-oil-refinery'),
		'palmoil-oleochemicals': t('invitePartner.oleochemicals'),
		'palmoil-storage': t('invitePartner.storage-company'),
		'palmoil-trader': t('invitePartner.trader'),
		'palmoil-endManufacturer': t('invitePartner.end-manufacturer'),
	}

	return orgTypes[type]
}

export const getOrganizationType = (t, name) => {
	const organizationTypes = getOrganizationTypes(t)

	return organizationTypes.find(item => item.name === name).label
}

export const getQuantityWithUnit = (currentQuantity, uomNonMetric, uom) => {
	const quantityInKg = uom === 'MT' ? `(${1000 * currentQuantity} Kg)` : ''
	const currentUom = `${uom || ''} ${quantityInKg}`
	const quantityWithUnit = `${currentQuantity || 0} ${uomNonMetric ||
		currentUom ||
		''}`

	return quantityWithUnit
}

export const convertToTitleCase = (value = '') =>
	(value || '').replace(/\w\S*/g, txt => {
		const Pieces =
			(txt.indexOf('_') > -1 &&
				txt.split('_').map(piece => convertToTitleCase(piece))) ||
			[]

		return Pieces.length > 0
			? Pieces.join('')
			: txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
	})

/**
 * @function serializeRedirectQuery
 * @param {*} queryObject { key:'value' }
 * @description changes query object into serilized query string
 */
export function serializeRedirectQuery(queryObject = {}) {
	const populatedQuery = Object.keys(queryObject)
		.map(
			(key, index) =>
				`${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(
					typeof queryObject[key] === 'string'
						? queryObject[key]
						: JSON.stringify(queryObject[key])
				)}`
		)
		.reduce((prev, next) => {
			const agg = prev + next

			return agg
		}, '')

	return populatedQuery
}

// function that takes in an array of primitive elements and returns an array with duplicates removed
export const removeDuplicates = arr => {
	const arrayMap = {}
	arr.forEach(element => {
		arrayMap[element] = element
	})

	return Object.keys(arrayMap)
}

export const intersectFlatArrays = (arrA = [], arrB = []) =>
	removeDuplicates(arrA.filter(x => arrB.includes(x)))

// removes whitespaces from string
export const removeWhitespaces = (text = '') => text.replace(/\s/g, '')

export const convertFirstCharToLower = (text = '') =>
	text.charAt(0).toLowerCase() + text.slice(1)

export function isEmptyObject(obj) {
	for (const key in obj)
		if (Object.prototype.hasOwnProperty.call(obj, key)) return false

	return true
}

export const getNextSortOrder = currentOrder => {
	if (currentOrder === 'asc') return 'desc'
	if (currentOrder === 'desc') return 'asc'

	if (!currentOrder || currentOrder === 'remove') return 'asc'
}

export function useDebouncedCallback(callback, delay = 0, options = {}) {
	return useCallback(debounce(callback, delay, options), [
		callback,
		delay,
		options,
	])
}

/**
 * A small helper to sort the array both side. NOTE: if hinted its already in ascending, then it just reverses the array
 * @param array a
 * @param string direction asc|desc
 * @param boolean isAscending
 */
export function sortArray(
	a,
	direction = 'asc',
	fieldParsingHook = _a => _a,
	alreadyInAscending = false
) {
	if (alreadyInAscending) {
		// sorted but in different order, reverse the order
		a.reverse() // or use for loop// assume it be unsorted, use sort alogorithm
	} else {
		a.sort((_a, _b) => {
			const __a = fieldParsingHook(_a)
			const __b = fieldParsingHook(_b)

			if (direction === 'asc') {
				if (__b > __a) {
					return -1
				}
				if (__a > __b) {
					return 1
				}
			} else {
				if (__a > __b) {
					return -1
				}
				if (__b > __a) {
					return 1
				}
			}

			return 0
		})
	}

	return a
}

export const pick = (object, keys = []) =>
	keys.reduce((agg, key) => {
		const obj = agg
		if (object && Object.prototype.hasOwnProperty.call(object, key)) {
			obj[key] = object[key]
		}

		return obj
	}, {})

export const getKeysByRegex = (obj = {}, regex = '') =>
	Object.keys(obj).filter(_key => _key.match(new RegExp(regex, 'i')))

const isEmptyObjOrArray = obj =>
	(typeof obj === 'object' && isEmptyObject(obj)) ||
	(Array.isArray(obj) && !obj.length)

export const pickBy = (sourceObj = {}) => {
	const obj = {}
	for (const key in sourceObj) {
		if (
			sourceObj[key] !== null &&
			sourceObj[key] !== false &&
			sourceObj[key] !== undefined &&
			!isEmptyObjOrArray(sourceObj[key])
		) {
			obj[key] = sourceObj[key]
		}
	}

	return obj
}

export const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 2), lastOdd }
}

// regex to match the url param post domain name in the url.
// eslint-disable-next-line no-useless-escape
const urlPathRegex = /.+?\/\/.+?(\/.+?)(?:#|\?|$)/i

export const getRelativeUrlFromAbsoluteUrl = (urlParam = '/') => {
	let url = urlParam.toString()
	const path = urlPathRegex.exec(url)
	if (path) {
		// eslint-disable-next-line prefer-destructuring
		url = path[1] || '/' // get the matched param.

		return url
	}

	return url.indexOf('/') !== 0 ? '/' : url
}

export const transformDataForBarChart = (data = [], type = '') => {
	const transformedData = []

	if (type === 'products' || type === 'storage-status') {
		data.forEach(d =>
			transformedData.push({ label: d.name, value: d.quantity })
		)
	}

	if (type === 'certified-suppliers') {
		data.forEach(d =>
			transformedData.push({ label: d.type, value: d.count })
		)
	}

	if (type === 'supplier-categories') {
		data.forEach(d =>
			transformedData.push({ label: d.name, value: d.count })
		)
	}

	return transformedData
}

export const transformDataforPieChart = (data = [], type = '') => {
	const transformedData = []

	if (type === 'best-suppliers') {
		data.forEach(d =>
			transformedData.push({ label: d.name, value: d.percent })
		)
	}

	if (type === 'best-smallholders') {
		data.forEach(d =>
			transformedData.push({ label: d.name, value: d.value })
		)
	}

	if (type === 'best-blocks') {
		data.forEach(d =>
			transformedData.push({
				label: d.name,
				value: d.productionPercent,
			})
		)
	}

	return transformedData
}

export const getProdList = ({ products }) => {
	const productList = []
	if (products && products.length > 0) {
		products.map(prod =>
			productList.push({
				id: prod.id,
				label: prod.name,
				quantity: prod.quantity,
				uom: prod.uom,
			})
		)
	}

	return productList
}

export const getSalesSvg = monthlySales => {
	if (monthlySales.currentMonth < monthlySales.previousMonth) {
		return "url('/images/red-wave.svg')"
	}
	if (monthlySales.currentMonth > monthlySales.previousMonth) {
		return "url('/images/green-wave.svg')"
	}
	if (monthlySales.currentMonth === monthlySales.previousMonth) {
		return "url('/images/blue-wave.svg')"
	}
}

export const getPurchaseSvg = monthlyPurchase => {
	if (monthlyPurchase.currentMonth < monthlyPurchase.previousMonth) {
		return "url('/images/red-wave.svg')"
	}
	if (monthlyPurchase.currentMonth > monthlyPurchase.previousMonth) {
		return "url('/images/green-wave.svg')"
	}
	if (monthlyPurchase.currentMonth === monthlyPurchase.previousMonth) {
		return "url('/images/blue-wave.svg')"
	}
}

export const transformCertforStore = details => {
	const certificates = details.map(_certificate => {
		const certificate = getIn(_certificate, ['meta', 'certificate']) || {}

		return {
			id: getIn(_certificate, ['id']) || '',
			certOrg: certificate.issuingBody || '',
			certNum: getIn(_certificate, ['number']) || '',
			certName:
				certificate.issuingBody === 'Others'
					? certificate.name
					: certificate.issuingBody || '',

			expiryDate:
				getDateByFormat(getIn(_certificate, ['expiryEndDate'])) || '',
			certStartDate:
				getDateByFormat(getIn(_certificate, ['expiryStartDate'])) || '',
			certBody: certificate.certificationBody || '',
			license:
				getIn(_certificate, ['meta', 'license', 'licenseNumber']) || '',
			issueDate:
				getDateByFormat(getIn(_certificate, ['issueDate'])) || '',
			createdAt:
				getDateByFormat(getIn(_certificate, ['createdAt'])) || '',
			scmodel:
				typeof certificate.supplychainModel === 'string'
					? [certificate.supplychainModel]
					: certificate.supplychainModel,
			files: getIn(_certificate, ['files']) || [],
			meta: getIn(_certificate, ['meta']) || {},
		}
	})

	return certificates
}

export const viewRFQTemplateKeys = [
	'Product Name',
	'Quantity',
	'Shipment Period',
	'Port of Lading',
	'Destination Port',
	'Supply Chain Model',
	'Preferred Quality Inspector',
	'Preferred Quantity Inspector',
	'Type of Contract',
	'Payment Terms',
	'Additional Details',
	'Terms And Conditions',
]

export const viewPRTemplateKeys = [
	'Product Name',
	'Quantity',
	'Shipment Period',
	'Port Of Lading',
	'Destination Port',
	'Supply Chain Model',
	'Third Party Inspection',
	'Preferred Quality Inspector',
	'Preferred Quantity Inspector',
	'Type Of Contract',
	'Payment Terms',
	'Additional Details',
	'Terms And Conditions',
]

export const getRandomNumber = () => {
	const crypto = window.crypto || window.msCrypto
	const array = new Uint32Array(1)
	crypto.getRandomValues(array) // Compliant for security-sensitive use cases
	const number = array[0]

	return number
}

export const getCertificateTypes = () => [
	{
		label: 'RSPO',
		id: 'RSPO',
	},
	{
		label: 'MSPO',
		id: 'MSPO',
	},
	{
		label: 'ISPO',
		id: 'ISPO',
	},
	{
		label: 'ISCC',
		id: 'ISCC',
	},
	{
		label: 'Uncertified',
		id: 'uncertified',
	},
]

export const getProductionLineList = productionLine => {
	if (productionLine.length > 0) {
		return productionLine.map(item => {
			return {
				name: item.id,
				label: item.name,
			}
		})
	}

	return []
}

export const getProductsList = products => {
	if (products.length > 0) {
		return products.map(item => {
			return {
				name: item.product.id,
				label: item.product.name,
			}
		})
	}

	return []
}

export const getStorageUnitsList = (
	storageUnits,
	capacityInLabel,
	quantityInLabel
) => {
	if (storageUnits.length > 0) {
		return storageUnits.map(item => {
			const availableQty =
				item?.meta?.storedProducts.reduce((sum, current) => {
					return sum + (current.availableQty || 0)
				}, 0) || 0

			return {
				name: item.id,
				products: item.products,
				capacity: item.capacity,
				availableQty,
				label:
					item.name +
					(quantityInLabel
						? ` (${availableQty
								.toString()
								.match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0} MT)`
						: '') +
					(capacityInLabel && item.capacity
						? ` (cap: ${item?.capacity}) MT`
						: ''),
				supplyChainModel: item.meta.supplyChainModel,
				certificationType: item.meta.certificationType,
			}
		})
	}

	return []
}

export const getStorageUnitsListWithProductQuantity = (
	storageUnits,
	productId,
	capacityInLabel,
	quantityInLabel
) => {
	if (storageUnits.length > 0) {
		return storageUnits.map(item => {
			const filteredStoredProducts =
				item?.meta?.storedProducts.filter(
					item => item.productID === productId
				) || []
			const productAvailableQty =
				filteredStoredProducts.length > 0
					? filteredStoredProducts[0].availableQty
					: 0
			const availableQty =
				item?.meta?.storedProducts.reduce((sum, current) => {
					return sum + (current.availableQty || 0)
				}, 0) || 0

			return {
				name: item.id,
				products: item.products,
				capacity: item.capacity,
				availableQty,
				productAvailableQty,
				label:
					item.name +
					(quantityInLabel
						? ` (${availableQty
								.toString()
								.match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0} MT)`
						: '') +
					(capacityInLabel && item.capacity
						? ` (cap: ${item.capacity}) MT`
						: ''),
				supplyChainModel: item.meta.supplyChainModel,
				certificationType: item.meta.certificationType,
			}
		})
	}

	return []
}

export const getStorageCapacity = (storageUnitsList, storageID) => {
	let capacity = 0
	if (storageUnitsList && storageUnitsList.length > 0) {
		const storageUnit = storageUnitsList.find(
			item => item.name === storageID
		)
		if (storageUnit) {
			capacity = storageUnit.capacity
		}
	}

	return capacity
}

export const getProductTotal = datum => {
	const tax = parseFloat(getIn(datum, ['tax', 0, 'value'])) || 0
	const unitPrice = parseFloat(datum.metricUnitPrice) || 0
	const quantity = parseFloat(datum.quantity) || 0

	const totalWithoutTax = unitPrice * quantity

	const total = parseFloat(totalWithoutTax + (tax * totalWithoutTax) / 100)

	return Number.isNaN(total) ? 0 : parseFloat(total.toFixed(2))
}

export const getTargetFilterQueries = (
	currentFilters,
	filterSegment,
	filterValue,
	prefix = ''
) => {
	let targetQueries = currentFilters || []
	targetQueries = Array.isArray(targetQueries)
		? targetQueries
		: [targetQueries]

	let targetFilterValue = `eq|${prefix}${filterValue}`
	const addMultiple = filterSegment === 'multiple'
	const notEqual = filterSegment === 'not-equal'
	const isStartDate = filterSegment === 'start_date'
	const isEndDate = filterSegment === 'end_date'
	const dateRange = filterSegment === 'date_range'
	const abc = filterSegment === 'abc'

	if (isStartDate) {
		// remove all existing start dates
		targetQueries = targetQueries.filter(q => q.indexOf('gte|') < 0)

		// add the end date range
		targetFilterValue = `gte|${prefix}${filterValue}`
	}

	if (isEndDate) {
		targetQueries = targetQueries.filter(q => q.indexOf('lte|') < 0)
		// add the end date range
		targetFilterValue = `lte|${prefix}${filterValue}`
	}

	if (addMultiple) {
		targetFilterValue = `in|${prefix}${filterValue}`
	}

	if (notEqual) {
		targetFilterValue = `neq|${prefix}${filterValue}`
	}
	if (dateRange) {
		targetFilterValue = `eq|${getIn(filterValue, [
			'startDate',
		])}~${getIn(filterValue, ['endDate'])}`
	}

	if (abc) {
		targetFilterValue = `cnt|${prefix}->${filterValue}`
	}

	// check if already in the filter
	if (!targetQueries.includes(targetFilterValue))
		targetQueries = addLast(targetQueries, targetFilterValue)

	return targetQueries
}
