import { PolicyDuc } from 'ui-auth-app/modules/Policy/duc'
import { getIn } from 'timm'

export default async (dispatch, getState, { action }) => {
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	// const state = getState()
	// Inject the saga here. If it wasn't already
	const state = getState()
	// extra.getSagaInjectors().injectSaga('listing', { saga })
	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	const activeModule = payload.action

	dispatch(PolicyDuc.creators.setActiveModule(activeModule))
}
