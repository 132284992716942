import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
import { CustomInput } from 'ui-auth-app/modules/Auth/components/CustomInput'
import { getAppVersion } from 'ui-auth-app/config'
import { isEmptyObject } from 'ui-auth-app/utils/helpers'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { H2, SmallText, Label } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import DibizIcon from 'ui-lib/icons/Dibizlogo.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'

const Illustration = styled.img`
	width: 90%;
`

const SignUp = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { query = {} } = useSelector(AuthDuc.selectors.location)
	const { email: unRegisteredEmailID, unregRfqEmail, companyname } = query
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email('Please check the email format')
			.required('Email is required'),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			email: unRegisteredEmailID || unregRfqEmail || '',
		},
		validationSchema,
		onSubmit: _values => {
			const data = { ..._values }
			if (companyname) {
				data.companyname = companyname
			}
			dispatch(
				MainRouteDuc.creators.redirect(
					MainRouteDuc.types.AUTH,
					{
						action: 'signup-detail',
					},
					data
				)
			)
		},
	})
	const disableCTA = !isEmptyObject(errors)

	const submitSignup = payload => {
		dispatch(
			MainRouteDuc.creators.redirect(
				MainRouteDuc.types.AUTH,
				{
					action: 'signup-detail',
				},
				payload
			)
		)
	}

	return (
		<Box>
			<Box
				style={{
					width: '100vw',
					height: '100vh',
					background: ' #1D285D',
					overflowY: 'scroll',
				}}
			>
				<Box>
					<Box padding={16}>
						<Box
							row
							justifyContent="flex-end"
							style={{
								paddingRight: '5%',
							}}
							alignItems="center"
						>
							<Label color="#A3ADDB">
								{t('login.haveAccount')}
							</Label>
							<Spacer size={32} />
							<Button
								label={t('login.signIn')}
								outline
								extendStyles={{
									color: theme.color.white,
									backgroundColor: 'transparent',
									border: '1px solid white',
								}}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.redirect(
											MainRouteDuc.types.AUTH,
											{
												action: 'sign-in',
											},
											{
												redirect_to: '/',
											}
										)
									)
								}
							/>
						</Box>
					</Box>
					<Box row padding={10} justifyContent="center">
						{!isMobile && (
							<Box>
								<Box
									padding={10}
									style={{
										background: theme.color.white,
										borderRadius: '4px',
										width: '180px',
									}}
								>
									<IconWrapper width={160} height={60}>
										<Icon glyph={DibizIcon} />
									</IconWrapper>
								</Box>
								<Spacer size={40} />
								<H2
									style={{
										fontSize: '40px',
										color: theme.color.white,
										maxWidth: '700px',
										lineHeight: '52px',
									}}
								>
									{t('login.welcome')}
								</H2>
								<Spacer size={20} />

								<SmallText
									color="#7D8AC7"
									fontSize="24px"
									style={{
										maxWidth: '700px',
									}}
								>
									{t('login.welcomeTagline')}
								</SmallText>
								<Spacer size={30} />
								<Box alignItems="baseline">
									<Illustration
										src="/images/DIBIZ_Marketplace.svg"
										alt="dibiz"
									/>
								</Box>
								<Spacer size={50} />

								<Box row alignItems="center">
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'terms-conditions',
													}
												)
											)
										}
										label="Terms & Conditions"
									/>
									<Spacer size={20} />
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'privacy-policy',
													}
												)
											)
										}
										label={t('login.privacy')}
									/>
									<Spacer size={20} />

									<SmallText color="#ABB4DB" fontSize="14px">
										{`Version ${getAppVersion()}`}
									</SmallText>
								</Box>
							</Box>
						)}
						<Box
							style={{
								width: '524px',
								height: 'fit-content',
								borderRadius: '10px',
								background: theme.color.white,
								boxShadow: '0px 3px 6px #00000029',
							}}
						>
							<Box
								style={{
									position: 'relative',
									top: isMobile ? '2%' : '0%',
									width: '100%',
									height: 'inherit',
									alignSelf: 'center',
									padding: isMobile
										? '19px 20px'
										: '19px 27px',
									zIndex: 1,
								}}
							>
								<Box alignItems="flex-end">
									<SmallText
										style={{
											color: '#ACB7CD',
											fontSize: '14px',
										}}
									>
										{t('login.mandatoryMessage')}
									</SmallText>
								</Box>
								{isMobile && (
									<Box
										padding="15px 0px"
										style={{
											background: theme.color.white,
											borderRadius: '4px',
										}}
									>
										<IconWrapper width={160} height={60}>
											<Icon glyph={DibizIcon} />
										</IconWrapper>
									</Box>
								)}
								<Box
									style={{
										padding: isMobile
											? '15px 0'
											: '15px 30px',
									}}
								>
									<H2
										style={{
											fontSize: '24px',
											color: '#2D2D2D',
										}}
									>
										{t('login.registerFree')}
									</H2>
									<Spacer size={40} />

									<form onSubmit={handleSubmit}>
										<Box style={{ width: '100%' }}>
											<CustomInput
												disabled={
													unRegisteredEmailID ||
													unregRfqEmail
												}
												value={values.email}
												handleChange={handleChange}
												handleBlur={handleBlur}
												touched={touched.email}
												errors={errors.email}
												label={t('login.workEmail')}
												name="email"
												type="email"
											/>
										</Box>
										<Box
											alignItems="left"
											overflow="visible"
										>
											<Button
												disabled={disableCTA}
												isLoading={isSubmitting}
												type="submit"
												primary
												label={t('login.continue')}
												style={{
													width: '100%',
												}}
											/>
										</Box>
										<Spacer size={20} />
									</form>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default SignUp
