import React from 'react'
import styled from 'styled-components'
import { P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'

const Wrapper = styled.div({ lineHeight: 2, textAlign: 'justify' })

const CTAWrapper = styled.span(p => ({
	...p.theme.fonts.bold,
	color: theme.color.black,
	textAlign: 'justify',
}))

const Title = styled.div(p => ({
	...p.theme.fonts.bold,
	fontSize: p.theme.fontSize.xl,
	paddingBottom: 4,
	color: p.theme.color.primary,
}))

export const OrderedList = styled.ol(p => ({
	overflow: 'hidden',
	paddingLeft: p.padding ? '48px' : '28px',
}))

export const ListItem = styled.li(p => ({
	margin: 'none',
	fontSize: p.bold ? p.theme.fontSize.xl : p.theme.fontSize.m,
	...(p.bold ? p.theme.fonts.bold : p.theme.fonts.regular),
	color: p.bold ? p.theme.color.primary : p.theme.color.black,
}))

const LinkBlock = styled.span(p => ({
	color: p.theme.color.primary,
	cursor: 'pointer',
	paddingLeft: '4px',
	':hover': {
		textDecoration: 'underline',
	},
}))

function TermsCondtions() {
	return (
		<Wrapper>
			<P>
				Please read this Agreement carefully. The Service may have other
				posted notices or codes of conduct. All such notices and codes
				of conduct are incorporated by reference into this Agreement. By
				subscribing to and/or using any of the Service, you agree to be
				bound by this Agreement, including any modifications made to it
				from time to time. If you do not agree to the terms and
				conditions in this Agreement, do not subscribe to or use the
				Service.
			</P>
			<Spacer size={12} />

			<Title>Definitions and Interpretation:</Title>

			<OrderedList padding>
				<ListItem>
					<CTAWrapper>You, Your, User </CTAWrapper>
					means the individual accessing or using the Service, or the
					company, or other legal entity on behalf of which such
					individual is accessing or using the Service, as applicable.
				</ListItem>
				<ListItem>
					<CTAWrapper>Account</CTAWrapper> means a unique account
					created for You to access our Service or parts of our
					Service.
				</ListItem>
				<ListItem>
					<CTAWrapper>Website</CTAWrapper> refers to DIBIZ, accessible
					from
					<LinkBlock
						onClick={() => {
							window.location.replace('https://dibizglobal.com')
						}}
					>
						https://dibizglobal.com/
					</LinkBlock>
				</ListItem>
				<ListItem>
					<CTAWrapper>Service</CTAWrapper> refers to the Website and
					customer acquisition, Supply Chain, and sustainability
					platform.
				</ListItem>
				<ListItem>
					<CTAWrapper>Service Provider</CTAWrapper> means any natural
					or legal person who processes the data on behalf of the
					Company. It refers to third-party companies or individuals
					employed by the Company to facilitate the Service, to
					provide the Service on behalf of the Company, to perform
					services related to the Service or to assist the Company in
					analyzing how the Service is used.
				</ListItem>
				<ListItem>
					<CTAWrapper>Supply Chain Data</CTAWrapper> refers to the
					data collected from documents like Purchase orders, Invoice,
					Delivery Copy etc
				</ListItem>
				<ListItem>
					<CTAWrapper>Privacy Policy</CTAWrapper> describes our
					policies and procedures on the collection and usage of
					Personal data, which can be accessed at
					<LinkBlock
						onClick={() => {
							window.location.replace('https://dibizglobal.com')
						}}
					>
						https://dibizglobal.com/
					</LinkBlock>
				</ListItem>
				<ListItem>
					<CTAWrapper>Standard technical support</CTAWrapper>
					includes on-line help, FAQ’s, training guides and templates
					and the use of live help and customer support.
				</ListItem>
				<ListItem>
					<CTAWrapper>Subscription Plan</CTAWrapper> refers to the
					various purchase and usage options of the dibizglobal in
					varied terms (Monthly, annual etc.,)
				</ListItem>
				<ListItem>
					<CTAWrapper>Periodic Maintenance</CTAWrapper> refers the
					scheduled maintenance window of the platform which may
					include a pre-notified down time.
				</ListItem>
			</OrderedList>

			<Title>In this Document: </Title>

			<OrderedList>
				<ListItem>Your Account </ListItem>
				<ListItem>Data Privacy and Retention </ListItem>
				<ListItem>Accessing and Using our Site and Services</ListItem>
				<ListItem>
					Technical Requirements for Use of our Services
				</ListItem>
				<ListItem>Applicable Law and Jurisdiction</ListItem>
				<ListItem>Intellectual Property Rights </ListItem>
				<ListItem>Liability of Supply Chain data </ListItem>
				<ListItem>Term and Termination </ListItem>
				<ListItem>Warranties </ListItem>
				<ListItem>Mutual Indemnification </ListItem>
				<ListItem>Limitation of Liability </ListItem>
			</OrderedList>

			<OrderedList>
				<Spacer size={16} />
				<ListItem bold> Your Account</ListItem>
				<OrderedList>
					<ListItem>
						<P>
							Initiating Service. Following receipt of payment for
							those charges specified in the Subscription plan as
							payable in advance, Dibiz will activate your account
							and begin providing the Services (the date of the
							initial service activation represents the “Service
							Activation or Provisioning Date”). The initial
							process creates your unique account and enables you
							to log in and access the Services. Once we have
							onboarded and enabled your account, you will need to
							visit our Administrative module and create an
							administrative account (an “Admin Account”). When
							you create your Admin Account, you will create your
							own password, which you must use in order to access
							and use the Services. You agree that any information
							you provide in creating your Admin Account is
							complete and accurate, and you agree to keep it up
							to date. For example, we provide some notices by
							email to your Admin Account, and you agree to keep
							that email current and valid as a method for
							providing notices under these Terms. Prices and
							terms of payment for the Service options you select
							are stated in your Subscription plan.
						</P>
					</ListItem>
					<ListItem>
						<P>
							Administering Your Account. Your administrators and
							supervisors can manage your use of the Services as
							well as perform other administrative functions. You
							are responsible for selecting your passwords,
							maintaining the confidentiality of your passwords,
							and internal management or administration of the
							Services for your Users. You agree to notify us
							immediately if you have any reason to believe that
							the security of your account has been compromised.
						</P>
					</ListItem>
				</OrderedList>

				<Spacer size={16} />
				<ListItem bold>Data Privacy and Retention</ListItem>
				<P>
					We will comply with the Privacy Policy (found at
					<LinkBlock
						onClick={() => {
							window.location.replace('https://dibizglobal.com')
						}}
					>
						https://www.dibizglobal.com/
					</LinkBlock>
					) in handling and using your User Data, and we will maintain
					administrative, physical, and technical safeguards at
					application level, database and various tenets of the
					platform for protection of the security, confidentiality and
					integrity of your User Data that are consistent with
					industry standards for similar services. You too shall
					comply with the Privacy Policy and shall not disclose to
					third parties or use Dibiz Data except as expressly
					permitted under these Terms. “User Data” means the
					information submitted by users into our system, including
					information derived from such submissions, data about the
					configuration and use of the Services, account information,
					credit card information, bank account information,
					accounting information, supply chain data, transactions and
					reports.
				</P>

				<Spacer size={16} />
				<ListItem bold>
					Accessing and Using Our Platform and Services
				</ListItem>
				<OrderedList>
					<ListItem>
						<P>
							Subscription Services. Subject to all the terms and
							conditions of this Agreement, including your payment
							of any required fees, Dibiz will make the Services
							available to you based on the subscription plan
							chosen. Subscription plans can be referred at
							<LinkBlock
								onClick={() => {
									window.location.replace(
										'https://dibizglobal.com'
									)
								}}
							>
								https://www.dibizglobal.com/.
							</LinkBlock>
						</P>
					</ListItem>
					<ListItem>
						<P>
							Limitations. Product Traceability, a Dibiz service
							shall be provided only to the extent how much other
							stakeholders in the supply chain have signed up; our
							commitment is limited to User only and not signing
							up other stakeholders.
						</P>
					</ListItem>
					<ListItem>
						<P>
							Restrictions. You will not nor will you allow any
							third party to: modify, copy, or otherwise reproduce
							the Services in whole or in part; reverse engineer,
							decompile, disassemble, or otherwise attempt to
							derive the source code form or structure of the
							software used in the Services; provide, lease or
							lend the Services to any third party; remove any
							proprietary notices or labels displayed on the
							Services; modify or create a derivative work of any
							part of the Services; or use the Services for any
							unlawful purpose or contrary to this Agreement. You
							will ensure that your Users comply with this
							Agreement by the use commercially reasonable efforts
							to prevent unauthorized access to or use of the
							Services; and (3) use the Services only in
							accordance with the applicable laws and government
							regulations.
						</P>
					</ListItem>
				</OrderedList>

				<Spacer size={16} />
				<ListItem bold>
					Technical Requirements for Use of Our Services
				</ListItem>
				<P>
					In order to utilize the Services, you will need to adhere to
					certain technical specifications and have certain minimum
					hardware, software and Internet connectivity. With your
					cooperation, and prior to activation of your Services, we
					conduct an assessment of your Required Equipment. We may
					recommend adjustments to the Required Equipment in order to
					meet the minimum technical requirements to utilize the
					Services. You are responsible for implementing any
					recommendations we make in this regard and also for the
					ownership and ongoing maintenance of Required Equipment.
					Dibiz’s recommendations with respect to Required Equipment
					do not constitute a guarantee or warranty as to the future
					suitability of the Required Equipment for operating the
					Services. You acknowledge and agree that, at all times, you
					are solely responsible for obtaining and supporting Required
					Equipment as necessary to use the Services.
					<Spacer size={12} />
					“Your failure to have the Required Equipment and to be
					responsible for supporting and maintaining the same SHALL
					NOT BE a basis for terminating this Agreement. This shall
					also NOT constitute a basis for you to seek a refund from
					DIBIZ.
				</P>

				<Spacer size={16} />
				<ListItem bold>Applicable Law and Jurisdiction</ListItem>
				<P>
					-This is a binding contract and the applicable law shall be
					the Laws of West Malaysia.
					<Spacer size={4} />
					-Each party to this Agreement agrees to submit to the
					jurisdiction of the West Malaysian Courts.
					<Spacer size={4} />
					-Every civil action instituted by either party shall not
					necessarily be preceded by a demand made in writing.
					<Spacer size={4} />
					-In the event DIBIZ concludes that there is a breach of this
					Agreement by you, DIBIZ has the right to immediately
					terminate this Agreement subject to DIBIZ notifying you of
					the same in writing and giving you a reasonable time (14
					days) to remedy the breach Unless the breach constitutes a
					material breach of this agreement in which event the
					termination is automatic without the necessity for dibiz to
					allow you reasonable time or any time to remedy the breach.
				</P>

				<Spacer size={16} />
				<ListItem bold>Intellectual Property Rights.</ListItem>
				<P>
					All intellectual property rights shall belong to and be
					under the exclusive ownership of DIBIZ
				</P>

				<Spacer size={16} />
				<ListItem bold>Liability of Supply Chain Data</ListItem>
				<P>
					By using our platform/service, you acknowledge and agree
					that you are legally responsible for the accuracy, legality,
					and completeness of all supply chain data you enter or
					upload onto the platform. You further agree to indemnify and
					hold harmless DIBIZ and its affiliates, officers, directors,
					employees, and agents from any claims, damages, liabilities,
					or expenses (including legal fees) arising out of or related
					to your use of the platform/service and any supply chain
					data you provide.
				</P>
				<P>
					You understand that the supply chain data you provide may
					impact various aspects of our operations and that of your
					trading partners, including but not limited
					to traceability, procurement, logistics, and compliance.
					Therefore, you agree to exercise due diligence and ensure
					that the information you enter is accurate, up-to-date, and
					does not infringe upon any third-party rights.
				</P>
				<P>
					DIBIZ  reserves the right to suspend or terminate your
					access to the platform/service if we determine, in our sole
					discretion, that you have provided false, misleading, or
					unauthorized information reported by your trading partners.
				</P>

				<Spacer size={16} />
				<ListItem bold> Term and Termination</ListItem>
				<OrderedList>
					<ListItem>
						<P>
							Term. Unless earlier terminated as provided in this
							Agreement, this Agreement commences on the Service
							Activation Date and shall continue the initial cycle
							and shall automatically renew for billing cycle
							thereafter, unless either party has provided notice
							of its intent not to renew this Agreement in
							accordance with Section 8.2. In no event may you
							terminate this Agreement on less than thirty (30)
							days written notice to us. Either party may
							terminate this Agreement for cause on thirty (30)
							days written notice of a material breach to the
							other party if the breach remains uncured at the
							expiration of the notice period. Non-payment of any
							service or other fees due is a material breach. If
							you materially breach this Agreement, we may,
							without limitation of our other rights and remedies,
							withhold further performance of our obligations
							under this Agreement. Free Subscription Period is
							liable to be terminated if not converted to a Paid
							Subscription within 1 month of the end of free
							subscription period.
						</P>
					</ListItem>
					<ListItem>
						<P>
							Effect of Termination. Upon termination or
							expiration of this Agreement for any reason: (1) all
							applicable licenses and other rights granted to you
							will immediately terminate; (2) we will make your
							User Data available to you (either from Dibiz or
							through a third party offsite storage provider) for
							up to thirty (30) days after termination. If the
							User wants to renew the subscription after the lapse
							of 30 days and wish to access their old data then
							the user need to pay the subscription charges for
							the lapsed period. After that thirty (30) day
							period, you will lose access to any data and we will
							have no obligation to maintain any, and will have
							the right to delete, all data related to the expired
							or terminated Agreement and we will have no further
							obligation to make such data available to you.
							Termination will not relieve you of the obligation
							to pay any fees due or payable to Dibiz incurred
							prior to the effective date of termination,
							including annual fees, implementation fees, training
							fees, User subscription fees, or any other fees or
							payments due under the Agreement.
						</P>
					</ListItem>
				</OrderedList>

				<Spacer size={16} />
				<ListItem bold>Warranties</ListItem>
				<OrderedList>
					<ListItem>
						<P>
							Warranty. Each party represents and warrants to the
							other party that (i) it has the power and authority
							to enter into and perform all obligations under this
							Agreement, and (ii) it will comply with all
							applicable laws in its performance under this
							Agreement.
						</P>
					</ListItem>
					<ListItem>
						<P>
							To the fullest extent permitted by applicable law,
							except as expressly provided herein, dibiz disclaims
							all warranties with respect to the services,
							third-party services, or materials provided by
							dibiz, whether express or implied by operation of
							law, representation statements, or otherwise,
							including but not limited to any implied warranty of
							merchantability, fitness for a particular purpose,
							or noninfringement. Each party will be solely and
							individually responsible to comply with all laws and
							regulations relating to its respective business
							operations. You agree that the Services may be
							inaccessible or inoperable due to scheduled periodic
							maintenance and upgrades or for reasons beyond our
							reasonable control including but not limited to (i)
							equipment malfunctions, or (ii) service
							interruptions caused by independent
							telecommunications providers that provide voice and
							data connectivity to your or our data centers.
						</P>
					</ListItem>
				</OrderedList>

				<Spacer size={16} />
				<ListItem bold>Mutual Indemnification</ListItem>
				<OrderedList>
					<ListItem>
						<P>
							Our Indemnity. We will defend, indemnify and hold
							you harmless against any claims, liabilities,
							expenses (including reasonable attorney’s fees), or
							damages arising from or relating to a third party
							claim alleging that your use of the Services
							infringes or misappropriates any U.S. patent,
							copyright, or trade secret of a third party. The
							foregoing indemnity does not extend to claims
							arising from or relating to third-party Services or
							claims covered by your indemnification in Section
							10.2 of this Agreement. In the event of a claim or
							threatened claim under this Section by a third
							party, we may, at our sole option, (A) revise the
							Services so that they are no longer infringing, (B)
							obtain the right for you to continue using the
							Services, or (C) terminate this Agreement and any
							applicable Subscription plan upon fifteen (15) days’
							notice.
						</P>
					</ListItem>
					<ListItem>
						<P>
							Your Indemnity. You will defend, indemnify and hold
							us harmless against any claims, liabilities,
							expenses (including reasonable and all attorney’s
							fees) or damages arising from or relating to a
							third-party claim related to or arising from your
							use of the Services other than those for which we
							will indemnify you under Section 10.1.
						</P>
					</ListItem>
					<ListItem>
						<P>
							Procedures. The party entitled to be indemnified
							(the “Indemnified Party”) will give prompt written
							notice to the other party (the “Indemnifying Party”)
							of any claim for which indemnification may be
							required under this Agreement. Failure to give such
							notice will not relieve the Indemnifying Party of
							its obligation to provide indemnification except if
							and to the extent that such failure materially and
							adversely affects the ability of the Indemnifying
							Party to defend the applicable claim. If the
							Indemnified Party notifies the Indemnifying Party of
							any claim asserted by a third party against the
							Indemnified Party that the Indemnifying Party
							acknowledges is a claim for which it must indemnify
							the Indemnified Party under this Agreement, the
							Indemnifying Party will be entitled to assume the
							defense and control of any such claim at its own
							cost and expense and the Indemnified Party will have
							the right to be represented by its own counsel at
							its own cost in such matters. Neither the
							Indemnifying Party nor the Indemnified Party will
							settle or dispose of any such matter in any manner
							that would adversely affect the rights or interests
							of the other party (including the obligation to
							indemnify hereunder) without the prior written
							consent of the other party, which will not be
							unreasonably withheld or delayed. Each party will
							reasonably cooperate with the other party and its
							counsel in the course of the defense of any such
							suit, claim or demand, such cooperation to include
							without limitation using reasonable efforts to
							provide or make available documents, information and
							witnesses.
						</P>
					</ListItem>
				</OrderedList>

				<Spacer size={16} />
				<ListItem bold>Limitation of Liability</ListItem>
				<P>
					Except for either party’s indemnification obligations under
					section 10 (mutual indemnification), in no event will either
					party be liable to the other for any special, indirect,
					consequential, incidental and punitive/exemplary damages to
					the extent permitted under applicable law, however caused
					and on any theory of liability arising in any way out of
					this agreement, even if notified of the possibility of such
					damage. Except in connection with a misappropriation of
					intellectual property, or obligations under section 2 (data
					privacy) or section 10 (mutual indemnification), the
					aggregate liability of one party to the other for damages
					under or arising from this agreement, regardless of the form
					of the action (and whether in contract or in tort) will be
					limited to the total amount payable to dibiz in one (1)
					month of service or the equivalent of &lt; &gt; (whichever
					is greater) preceding the applicable claim under this
					agreement
				</P>
			</OrderedList>
		</Wrapper>
	)
}

export default TermsCondtions
