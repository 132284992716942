import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { Toast } from 'ui-lib/components/Toast'
import { simpleHashCode } from 'ui-lib/utils/helpers'
import { useTranslation } from 'react-i18next'

let toastsHashPipeline = []
const ToastHandler = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile, isTablet } = useSelector(AppDuc.selectors.detection)
	const toasts = useSelector(AppDuc.selectors.toasts)

	useEffect(() => {
		toasts.forEach((toast, index) => {
			if (toast === 'void') return

			const hash = simpleHashCode(toast.message)

			if (toastsHashPipeline.includes(hash)) {
				return
			}

			toastsHashPipeline.push(hash)
			Toast({
				type: toast.messageType,
				message: t(toast.message),
				isMobile: isMobile || isTablet,
				onClose: () => {
					setTimeout(() => {
						toastsHashPipeline = toastsHashPipeline.filter(
							_hash => _hash !== hash
						)
					}, 300)

					dispatch(AppDuc.creators.hideToast(index))
				},
			})
		})
	}, [toasts, isMobile, isTablet, dispatch, t])

	return null
}

export { ToastHandler }
