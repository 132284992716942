import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H2, SmallText, Label } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import EmailIcon from 'ui-lib/icons/drafts_black_48dp.svg'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { getIn } from 'timm'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getAppVersion } from 'ui-auth-app/config'
import { useTranslation } from 'react-i18next'

const VerifyEmail = () => {
	const { query = {} } = useSelector(AuthDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { t } = useTranslation()

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
		>
			<Box center padding="0 8px">
				<H2
					style={{
						fontSize: isMobile ? 28 : 40,
						color: theme.color.white,
						lineHeight: '52px',
						fontWeight: 'bold',
					}}
				>
					Confirm your account
				</H2>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<IconWrapper color="#CE924B" size={90}>
						<Icon glyph={EmailIcon} />
					</IconWrapper>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							width: isMobile ? '300px' : '500px',
							textAlign: 'center',
						}}
					>
						Thanks for registering with us. Your account is almost
						ready. If the account is not already registered, you
						will receive a verification email from us.
					</SmallText>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						Note: If you haven’t received the email please check
						your spam mail as well.
					</SmallText>
					<Spacer size={40} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '18px',
							fontWeight: 'bold',
						}}
					>
						{getIn(query, ['email'])}
					</SmallText>
					<Spacer size={40} />
					<Label
						style={{
							color: theme.color.white,
							fontSize: '18px',
							background: '#DE5757',
							borderRadius: '4px',
							padding: '10px 50px',
						}}
					>
						Check your email address
					</Label>
				</Box>
			</Box>
			<Box
				row
				style={{
					width: '100%',
					position: 'absolute',
					bottom: 0,
					marginBottom: '1px',
				}}
				alignItems="center"
				justifyContent="center"
			>
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/terms-conditions', '_blank')}
					label="Terms & Conditions"
				/>
				<Spacer size={20} />
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/privacy-policy', '_blank')}
					label={t('login.privacy')}
				/>
				<Spacer size={20} />

				<SmallText color="#ABB4DB" fontSize="14px">
					{`Version ${getAppVersion()}`}
				</SmallText>
			</Box>
		</Box>
	)
}

export default VerifyEmail
