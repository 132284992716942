/* eslint-disable array-callback-return */
/* @flow */

import React, { Suspense, useEffect } from 'react'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
// $FlowFixMe
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Auth from 'ui-auth-app/modules/Auth'
import {
	UnexpectedErrorBlock,
	NotFoundBlock,
	NotVerifiedBlock,
} from 'ui-auth-app/modules/App/components/Error'
import ErrorBoundary from 'ui-auth-app/modules/App/components/ErrorBoundary'
import { ToastHandler } from 'ui-auth-app/modules/App/components/ToastHandler'
import Policy from 'ui-auth-app/modules/Policy'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { LoaderFixedWrapper } from 'ui-lib/components/Spinner'
import { Modal } from 'ui-lib/components/Modal'
import { P } from 'ui-lib/components/Typography'
import { NewErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { InfiniteSpinner } from 'ui-lib/components/Spinner/InfiniteSpinner'
import { useInjectSaga } from 'ui-auth-app/store/injectSaga'
import { CookieBanner } from 'ui-lib/components/CookieBanner'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import saga from './AppSaga'
import networkSaga from './networkSaga'

/* The ui-auth routing map that drive the
	active root component controlled by `page` state key
	via routes/duc.js
*/
const ROUTING_COMPONENTS_MAP = {
	Auth,
	Policy,
}

const Wrapper = styled.div(p => ({
	position: 'relative',
	width: 'auto',
	minHeight: '100vh',
	background: `${p.theme.color.white1} url("/images/hex.png") repeat 0 0`,
	backgroundSize: '16px auto',
	margin: '0 auto',
}))

const Container = styled.div(p => ({
	width: '100%',
	margin: '0 auto',
	...(!p.isOnline && {
		paddingTop: 8,
		'-webkit-filter': 'grayscale(1)' /* Older Webkit */,
		'-moz-filter': 'grayscale(100%)',
		'-ms-filter': 'grayscale(100%)',
		'-o-filter': 'grayscale(100%)',
		filter: 'grayscale(100%)',
	}),
}))

const Loader = () => (
	<LoaderFixedWrapper fixed>
		<InfiniteSpinner size={30} />
	</LoaderFixedWrapper>
)
export const sentMessage = []

const App = () => {
	const isVerified =
		useSelector(AuthDuc.selectors.checkVerified) || 'verified'
	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)
	const dispatch = useDispatch()
	useInjectSaga({ key: 'app', saga })
	useInjectSaga({ key: 'network-handler', saga: networkSaga })

	const page = useSelector(MainRouteDuc.selectors.page)
	const {
		isFetching: isProfileInfoFetching,
		isError: isProfileFetchErrored,
	} = useSelector(AuthDuc.selectors.getProfileFetchStatus)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isOnline = useSelector(AppDuc.selectors.isOnline)
	const {
		show: showConfirmationModal,
		heading,
		message,
		isCloseable,
		confirmationLabel,
		declineLabel,
	} = useSelector(AppDuc.selectors.confirmationModal)

	const {
		show: showUpdateModal,
		heading: updateModalHeading,
		message: updateModaleMessage,
		isCloseable: updateModalClosable,
		confirmationLabel: updateModalConfirmLabel,
		declineLabel: updateDeclineLabel,
	} = useSelector(AppDuc.selectors.appUpdateModal)

	const globalLoadingStatus = useSelector(AppDuc.selectors.loading)
	const CoreComponent = ROUTING_COMPONENTS_MAP[page] || NotFoundBlock

	useEffect(() => {
		// which runs in each minute
		window.setInterval(() => {
			const currentPage = window.location.pathname.split('/')[1]
			if (
				!(
					currentPage === 'auth' ||
					currentPage === 'signup' ||
					currentPage === 'signup-detail'
				)
			) {
				dispatch(AuthDuc.creators.initiateRefreshToken())
			}
		}, 60000)
	}, [dispatch])

	// if auth route, return back the full exclusive component
	if (page === 'Auth' || page === 'Policy') {
		return (
			<Suspense fallback={<Loader />}>
				<ToastHandler />
				{!isIframeForTeamsApp() && <CookieBanner />}
				<CoreComponent />
			</Suspense>
		)
	}

	const isPageAErrorType = ['401', '500'].includes(page)
	const isLoading = globalLoadingStatus || isProfileInfoFetching

	if (isProfileFetchErrored || isPageAErrorType) {
		// unable to get the user profile info, so show error page.
		return (
			<div width="100vw" height="100vh">
				<UnexpectedErrorBlock
					status={isPageAErrorType ? page : 401}
					onClick={
						!isProfileFetchErrored
							? () => window.location.reload()
							: () => {}
					}
				/>
			</div>
		)
	}

	const getSideBarMargin = () => {
		let margin = '16px 0 0 310px'
		if (isMobile) {
			margin = '0 auto'
		} else if (isIframeForTeamsApp()) {
			margin = '0px'
		} else if (!isOnline) {
			margin = '45px 0 0 310px'
		}

		return margin
	}

	const getMaxWidth = () => {
		let width = 'calc(100vw - 338px)'
		if (isIframeForTeamsApp()) {
			width = '100%'
		}

		return width
	}

	return (
		<Wrapper>
			<ErrorBoundary>
				<Suspense fallback={<Loader />}>
					{!(
						isVerified === 'in-review' ||
						isVerified === 'verified' ||
						virtualAccess
					) ? (
						<NotVerifiedBlock />
					) : (
						<>
							<ToastHandler />
							{isOnline ? (
								<Container
									isOnline={isOnline}
									style={{
										maxWidth: isMobile
											? '100vw'
											: getMaxWidth(),
										margin: getSideBarMargin(),
										paddingBottom: isIframeForTeamsApp()
											? '0'
											: '90px',
										paddingRight: isIframeForTeamsApp()
											? '75px'
											: '0px',
									}}
								>
									{isLoading && <Loader />}
									<div id="DashboardHeader">
										<CoreComponent />
									</div>
								</Container>
							) : (
								<div
									style={{
										width: '100%',
										maxWidth: isMobile
											? '100vw'
											: 'calc(100vw - 338px)',
										margin: getSideBarMargin(),
										overflow: 'auto',
										paddingBottom: '90px',
									}}
								>
									<NewErrorBlock
										status="offline"
										title="DIBIZ seems to be offline."
										subtitle="Make sure that you're connected to the internet and try again"
									/>
								</div>
							)}
						</>
					)}
				</Suspense>
			</ErrorBoundary>
			<Modal
				closeable={isCloseable}
				show={showConfirmationModal}
				heading={heading}
				body={
					<P large bold>
						{message}
					</P>
				}
				closelabel={declineLabel}
				confirmlabel={confirmationLabel}
				onClose={() => {
					dispatch(AppDuc.creators.hideConfirmationModal())
					dispatch(AppDuc.creators.declinedOnConfirmationModal())
				}}
				onConfirm={() => {
					dispatch(AppDuc.creators.hideConfirmationModal())
					dispatch(AppDuc.creators.acceptedOnConfirmationModal())
				}}
				isMobile={isMobile}
			/>
			<Modal
				closeable={updateModalClosable}
				show={showUpdateModal}
				heading={updateModalHeading}
				body={
					<P large bold>
						{updateModaleMessage}
					</P>
				}
				closelabel={updateDeclineLabel}
				confirmlabel={updateModalConfirmLabel}
				onClose={() => {
					dispatch(AppDuc.creators.hideUpdateModal())
					dispatch(AppDuc.creators.updateRejectStatus(true))
				}}
				onConfirm={() => {
					dispatch(AppDuc.creators.hideUpdateModal())
					dispatch(AppDuc.creators.updateRejectStatus(false))
					dispatch(AppDuc.creators.updateAppVersion())
					window.location.reload(true)
				}}
				isMobile={isMobile}
			/>
		</Wrapper>
	)
}

export default App
