import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'
import { getCountries } from 'country-state-picker'
import Turnstile from 'react-turnstile'
import { useFormik } from 'formik'
import { CheckBox } from 'grommet'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
import { CustomInput } from 'ui-auth-app/modules/Auth/components/CustomInput'
import TermsCondtions from 'ui-auth-app/modules/Policy/containers/TermsCondtions'
import PrivacyPolicy from 'ui-auth-app/modules/Policy/containers/PrivacyPolicy'
import {
	featureFlags,
	getRecaptchaKey,
	getAppVersion,
} from 'ui-auth-app/config'
import { isEmptyObject, getRandomNumber } from 'ui-auth-app/utils/helpers'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Modal } from 'ui-lib/components/Modal'
import { Select, SelectSearch, CreatableSelect } from 'ui-lib/components/Select'
import { H2, SmallText, Label } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import DibizIcon from 'ui-lib/icons/Dibizlogo.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import {
	ALPHANUMERICWITHLIMITEDSPECIALREGEX,
	SCRIPTREPLACEREGEX,
} from 'ui-auth-app/utils/constants'
import { CookieDuc } from 'ui-auth-app/modules/App/cookieDuc'
import { Storage } from 'ui-lib/utils/storage'
import { getOrganisationTypes } from 'ui-lib/utils/helpers'

const Illustration = styled.img`
	width: 90%;
`

const SignupDetail = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { query = {} } = useSelector(AuthDuc.selectors.location)
	const {
		email: unRegisteredEmailID,
		unregRfqEmail,
		companyname,
		name,
		provider,
	} = query

	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)

	const [policyStatus, setPolicyStatus] = useState(false)
	const [
		agreeWithoutPolicyStatusRead,
		setAgreeWithoutPolicyStatusRead,
	] = useState(false)

	const [authorizedUser, setauthorizedUser] = useState(false)
	const [termsStatus, setTermsStatus] = useState(false)
	const [resetCaptcha, setResetCaptcha] = useState(getRandomNumber())

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email('Please check the email format')
			.required('Email is required'),
		orgName: yup
			.string()
			.required('Company Name is required')
			.min(5, t('validation.minLength', { number: 5 }))
			.max(128, t('validation.maxLength', { number: 128 })),
		firstName: yup
			.string()
			.required('Name is required')
			.min(5, t('validation.minLength', { number: 5 }))
			.max(64, t('validation.maxLength', { number: 64 })),
		organisationType: yup.object().shape({
			name: yup.string().required(),
			id: yup.string().required(),
		}),
		country: yup.string().required('Country name is required'),
		recaptcha: yup.string().required('Please prove humanity'),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
		setFieldValue,
	} = useFormik({
		initialValues: {
			email: unRegisteredEmailID || unregRfqEmail || '',
			firstName: name || '',
			lastName: '',
			orgName: companyname || '',
			phone: '',
			dialCode: '',
			organisationType: {
				name: '',
				id: '',
			},
			recaptcha: featureFlags.enableLoginCaptcha ? '' : 'disabled',
			state: '',
			country: '',
			termsAgree: false,
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			// eslint-disable-next-line no-param-reassign
			_values.enableLoginCaptcha = featureFlags.enableLoginCaptcha

			// replace all script tags
			_values.firstName = _values.firstName.replace(
				SCRIPTREPLACEREGEX,
				''
			)
			_values.orgName = _values.orgName.replace(SCRIPTREPLACEREGEX, '')
			// replace all script tags ends

			const data = { ..._values }
			if (query && query.tag) {
				data.tag = query.tag
			}
			let postData = data
			if (unRegisteredEmailID) {
				postData = { ...data, unRegisteredEmailID }
			} else if (unregRfqEmail) {
				postData = { ...data, unregRfqEmail }
			}
			const token = Storage.get({ name: 'code' })
			if (provider && token) {
				postData.provider = provider
				postData.token = token
				dispatch(
					CookieDuc.creators.deleteCookie({
						cookieName: 'code',
					})
				)
			}
			setResetCaptcha(getRandomNumber())
			dispatch(
				AuthDuc.creators.SignUp(postData, {
					setSubmitting,
				})
			)
		},
	})
	const disableCTA =
		!isEmptyObject(errors) || !values.termsAgree || !authorizedUser

	const refreshAfterAInterval = () => {
		setTimeout(() => {
			window.location.reload()
		}, 2000)
	}

	const countries = getCountries()

	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})

	return (
		<Box>
			<Modal
				forceCloseviaButton
				heading={t('onboarding.terms&Cond')}
				icon={DibizIcon}
				show={termsStatus}
				boxModal
				body={<TermsCondtions />}
				closelabel={t('onboarding.disagree')}
				confirmlabel={t('onboarding.agree')}
				size="large"
				onClose={() => {
					setTermsStatus(false)
				}}
				onConfirm={() => {
					setTermsStatus(true)
					setPolicyStatus(true)
				}}
				sideNote={t('onboarding.termsNote')}
			/>
			<Modal
				forceCloseviaButton
				heading={t('onboarding.privacy')}
				icon={DibizIcon}
				boxModal
				show={policyStatus}
				body={<PrivacyPolicy />}
				closelabel={t('onboarding.disagree')}
				confirmlabel={t('onboarding.agree')}
				size="large"
				onClose={() => {
					setTermsStatus(false)
					setPolicyStatus(false)
				}}
				onConfirm={() => {
					setFieldValue('termsAgree', true)
					setAgreeWithoutPolicyStatusRead(true)
					setPolicyStatus(true)
				}}
				sideNote={t('onboarding.privacyNote')}
			/>

			<Box
				style={{
					width: '100vw',
					height: '100vh',
					background: ' #1D285D',
					overflowY: 'scroll',
				}}
			>
				<Box>
					<Box padding={16}>
						<Box
							row
							justifyContent="flex-end"
							style={{
								paddingRight: '5%',
							}}
							alignItems="center"
						>
							<Label color="#A3ADDB">
								If you already have an account
							</Label>
							<Spacer size={32} />
							<Button
								label={t('login.signIn')}
								outline
								extendStyles={{
									color: theme.color.white,
									backgroundColor: 'transparent',
									border: '1px solid white',
								}}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.redirect(
											MainRouteDuc.types.AUTH,
											{
												action: 'sign-in',
											},
											{
												redirect_to: '/',
											}
										)
									)
								}
							/>
						</Box>
					</Box>
					<Box row padding={10} justifyContent="center">
						{!isMobile && (
							<Box>
								<Box
									padding={10}
									style={{
										background: theme.color.white,
										borderRadius: '4px',
										width: '180px',
									}}
								>
									<IconWrapper width={160} height={60}>
										<Icon glyph={DibizIcon} />
									</IconWrapper>
								</Box>
								<Spacer size={40} />
								<H2
									style={{
										fontSize: '40px',
										color: theme.color.white,
										maxWidth: '700px',
										lineHeight: '52px',
									}}
								>
									Welcome to DIBIZ Platform
								</H2>
								<Spacer size={20} />

								<SmallText
									color="#7D8AC7"
									fontSize="24px"
									style={{
										maxWidth: '700px',
									}}
								>
									A single-window platform for collaboration
									in sustainable supply chains for compliance
									to global sustainability standards
								</SmallText>
								<Spacer size={30} />
								<Box alignItems="baseline">
									<Illustration
										src="/images/DIBIZ_Marketplace.svg"
										alt="dibiz"
									/>
								</Box>
								<Spacer size={50} />

								<Box row alignItems="center">
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'terms-conditions',
													}
												)
											)
										}
										label="Terms & Conditions"
									/>
									<Spacer size={20} />
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'privacy-policy',
													}
												)
											)
										}
										label={t('login.privacy')}
									/>
									<Spacer size={20} />

									<SmallText color="#ABB4DB" fontSize="14px">
										{`Version ${getAppVersion()}`}
									</SmallText>
								</Box>
							</Box>
						)}
						<Box
							style={{
								width: '524px',
								height: 'fit-content',
								borderRadius: '10px',
								background: theme.color.white,
								boxShadow: '0px 3px 6px #00000029',
							}}
						>
							<Box
								style={{
									position: 'relative',
									top: isMobile ? '2%' : '0%',
									width: '100%',
									height: 'inherit',
									alignSelf: 'center',
									padding: isMobile
										? '19px 20px'
										: '19px 27px',
									zIndex: 1,
								}}
							>
								<Box alignItems="flex-end">
									<SmallText
										style={{
											color: '#ACB7CD',
											fontSize: '14px',
										}}
									>
										* All fields are mandatory
									</SmallText>
								</Box>
								{isMobile && (
									<Box
										padding="15px 0px"
										style={{
											background: theme.color.white,
											borderRadius: '4px',
										}}
									>
										<IconWrapper width={160} height={60}>
											<Icon glyph={DibizIcon} />
										</IconWrapper>
									</Box>
								)}
								<Box
									style={{
										padding: isMobile
											? '15px 0'
											: '15px 30px',
									}}
								>
									<H2
										style={{
											fontSize: '24px',
											color: '#2D2D2D',
										}}
									>
										Register your free account
									</H2>
									<Spacer size={40} />

									<form onSubmit={handleSubmit}>
										<Box style={{ width: '100%' }} overflow>
											<Label
												style={{
													fontSize: '14px',
													color: '#555454',
												}}
											>
												{t('login.companyName')}
											</Label>
											<Spacer size={8} />
											<CreatableSelect
												name="orgName"
												type="text"
												options={globalOrgs}
												value={values?.orgName}
												onChange={keyValue => {
													setFieldValue(
														'orgName',
														keyValue.replace(
															ALPHANUMERICWITHLIMITEDSPECIALREGEX,
															''
														)
													)
												}}
												disableBoxShadow
												onBlur={handleBlur}
												error={
													touched.orgName &&
													errors.orgName
												}
												minLenth={3}
												returnOnlyValue
											/>
											<div
												style={{
													color: theme.color.error,
													fontSize: 12,
												}}
											>
												{errors.orgName}
											</div>
										</Box>
										<Spacer size={32} />

										<Box style={{ width: '100%' }}>
											<CustomInput
												regex={
													ALPHANUMERICWITHLIMITEDSPECIALREGEX
												}
												value={values.firstName}
												handleChange={handleChange}
												handleBlur={handleBlur}
												touched={touched.firstName}
												errors={errors.firstName}
												label={t('login.fullName')}
												name="firstName"
												type="text"
											/>
										</Box>
										<Box style={{ width: '100%' }}>
											<CustomInput
												disabled={
													unRegisteredEmailID ||
													unregRfqEmail
												}
												value={values.email}
												handleChange={handleChange}
												handleBlur={handleBlur}
												touched={touched.email}
												errors={errors.email}
												label={t('login.workEmail')}
												name="email"
												type="email"
											/>
										</Box>
										<Box style={{ width: '100%' }}>
											<Box
												style={{
													width: '100%',
												}}
											>
												<Label
													required
													style={{
														fontSize: '14px',
														color: '#555454',
													}}
												>
													{t('onboarding.country')}
												</Label>
												<SelectSearch
													required
													name="country-list"
													value={values.country}
													options={countryList}
													onChange={value => {
														if (
															!isEmptyObject(
																value
															)
														) {
															setFieldValue(
																'country',
																value.label
															)
														}
													}}
													labelKey="label"
													valueKey="name"
													defaultLabel={t(
														'common.selectDefaultLabel'
													)}
													returnOnlyValue
													onBlur={handleBlur}
													touched={touched.country}
													errors={errors.country}
													style={{
														width: '100%',
														marginTop: '8px',
														border:
															'1px solid #ACB7CD',
														borderRadius: '4px',
														opacity: 1,
													}}
												/>
											</Box>
										</Box>

										<Box
											row={!isMobile}
											alignItems="baseline"
											justifyContent="space-between"
										>
											<Label
												style={{
													fontSize: '14px',
													color: '#555454',
												}}
											>
												{t('onboarding.orgType')}
											</Label>
											<Box
												row
												alignItems="center"
												style={{
													backgroundColor: '#CE924B',
													padding: '4px 10px',
													borderRadius: '4px',
													opacity: 1,
												}}
											>
												<Label
													style={{
														color:
															theme.color.white,
														fontSize: '12px',
													}}
													bold
												>
													CHOOSE THE RIGHT TYPE
												</Label>
											</Box>
										</Box>

										<Select
											required
											name="org-type-list"
											placeholder={t(
												'login.orgTypeSelect'
											)}
											options={getOrganisationTypes()}
											labelKey="name"
											valueKey="id"
											value={values.organisationType.id}
											onChange={value => {
												setFieldValue(
													'organisationType',
													value
												)
											}}
											error={
												touched.organisationType &&
												errors.organisationType
											}
											style={{
												width: '100%',
												marginTop: '8px',
												border: '1px solid #ACB7CD',
												borderRadius: '4px',
												opacity: 1,
											}}
											returnOnlyValue
											dropHeight="small"
										/>

										<Box
											style={{
												flexDirection: 'row',
												paddingBottom: 10,
												alignItems: 'center',
											}}
										>
											<CheckBox
												checked={
													agreeWithoutPolicyStatusRead
												}
												onChange={() => {
													setAgreeWithoutPolicyStatusRead(
														!agreeWithoutPolicyStatusRead
													)
													// setPolicyStatus(true)
													setFieldValue(
														'termsAgree',
														!agreeWithoutPolicyStatusRead
													)
												}}
											/>
											<Spacer size={12} horizontal />

											<SmallText
												color="#82868E"
												fontSize="14px"
											>
												{t('onboarding.agreeTo')}
												&nbsp;
												<SmallText
													style={{
														cursor: 'pointer',
														border: 'none',
														textDecoration: 'none',
														color:
															theme.color.blue4,
													}}
													onClick={() =>
														setTermsStatus(true)
													}
												>
													{t('login.terms&Policy')}
												</SmallText>
											</SmallText>
										</Box>
										<Box
											style={{
												flexDirection: 'row',
												paddingBottom: 10,
											}}
										>
											<CheckBox
												checked={authorizedUser}
												label={
													<SmallText
														color="#82868E"
														fontSize="14px"
													>
														{t(
															'onboarding.authorization'
														)}
													</SmallText>
												}
												onChange={() => {
													setauthorizedUser(
														!authorizedUser
													)
												}}
											/>
										</Box>
										<Spacer size={10} />

										{featureFlags.enableLoginCaptcha && (
											<div key={resetCaptcha}>
												<Turnstile
													id="dibiz-captcha"
													refreshExpired="manual"
													sitekey={getRecaptchaKey()}
													onVerify={response => {
														setFieldValue(
															'recaptcha',
															response || ''
														)
													}}
													onExpire={() => {
														if (navigator.onLine) {
															setResetCaptcha(
																getRandomNumber()
															)
														} else {
															refreshAfterAInterval()
														}
													}}
													onError={response => {
														refreshAfterAInterval()
													}}
												/>
											</div>
										)}
										<Spacer size={20} />

										<Box
											alignItems="left"
											overflow="visible"
										>
											<Button
												disabled={disableCTA}
												isLoading={isSubmitting}
												type="submit"
												primary
												label={t('onboarding.register')}
												style={{
													width: '100%',
												}}
											/>
										</Box>
										<Spacer size={32} />
									</form>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default SignupDetail
