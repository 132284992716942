import React from 'react'
import { CookieBanner as CookieBannerWrapper } from '@palmabit/react-cookie-law'

const CookieBanner = ({}) => {
	return (
		<CookieBannerWrapper
			message="This site or third-party tools used by this make use of cookies necessary for the operation and useful for the purposes outlined in the cookie policy. To learn more or opt out, see the cookie policy. By accepting, you consent to the use of cookies."
			wholeDomain={true}
			onAccept={() => {}}
			onAcceptPreferences={() => {}}
			onAcceptStatistics={() => {}}
			onAcceptMarketing={() => {}}
			privacyPolicyLinkText="Cookie Policy"
			policyLink="https://dibizglobal.com/#/cookie-policy"
		/>
	)
}

CookieBanner.defaultProps = {
	getLastTrackHeight: () => {},
	onClick: () => {},
}

export { CookieBanner }
