/* global dataLayer, clevertap, bmsMQTTClient, _cc10651 */

/**
 * This is a analytics tracking middleware. Right now check is an action has meta of the below shape it gets tracked.
 *
 * action = {
 * 	type: <SomeType>,
 * 	payload: { somePayload },
 *  meta: {
 * 			analytics: { // can be an array of same shape
 * 				type: 'ga',wq
 * 				name, // required
 *				appC, // optional
 *				category, //optional
 *				action // optional,
 *				label // optional,
 *				path // optional,
 * 			}
 * 		}
 * }
 */
// import LogHelper from 'ui-auth-app/utils/logger'
// import { getIn } from 'timm'

// // initialize the logger for this component.
// const logger = LogHelper('redux:analyticsMiddleware')
// import { slugify, convertToTitleCase } from 'ui-auth-app/utils/helpers'

export default () =>
	// select = ({ meta }) => meta.analytics
	() => next => action => {
		const returnValue = next(action)
		/*
		 * ga: Google Analytics
		 * WR: Clever Tap
		 * CS: Clickstream
		 * FBP: Facebook Pixel
		 */
		/** const track = {
		ga: typeof dataLayer !== 'undefined' && dataLayer,
		WR: typeof clevertap !== 'undefined' && clevertap,
		CS: typeof bmsMQTTClient !== 'undefined' && bmsMQTTClient,
		FBP: typeof dataLayer !== 'undefined' && dataLayer,
		LT: typeof _cc10651 !== 'undefined' && _cc10651,
	}

	if (!action || !action.meta || !__CLIENT__) {
		return returnValue
	}

	const event = select(action)
	if (!event) {
		return returnValue
	}

	;(Array.isArray(event) ? event : [event]).forEach(eventObj => {
		const tracker = eventObj ? track[eventObj.type] : null
		if (!tracker) return returnValue
	}) */

		return returnValue
	}
