/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { CookieDuc } from 'ui-auth-app/modules/App/cookieDuc'
import { MainRouteDuc as PageDuc } from 'ui-auth-app/routes/duc'
import { PolicyDuc } from 'ui-auth-app/modules/Policy/duc'
import { i18nextReducer } from 'i18next-redux-languagedetector'
import { DeviceDetectionDuc } from './deviceDetectionDuc'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
	return combineReducers({
		i18next: i18nextReducer,
		app: reduceReducers(
			AppDuc.reducer,
			CookieDuc.reducer,
			DeviceDetectionDuc.reducer
		),
		auth: AuthDuc.reducer,
		page: PageDuc.reducer,
		policy: PolicyDuc.reducer,
		...injectedReducers,
	})
}
