import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Turnstile from 'react-turnstile'
import DibizIcon from 'ui-lib/icons/Dibizlogo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import {
	getAppVersion,
	featureFlags,
	getRecaptchaKey,
} from 'ui-auth-app/config'
import { isEmptyObject, getRandomNumber } from 'ui-auth-app/utils/helpers'
import { Input, PasswordInput } from 'ui-lib/components/Input'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { H2, SmallText, Label } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import { Modal } from 'ui-lib/components/Modal'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import * as yup from 'yup'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import TermsCondtions from 'ui-auth-app/modules/Policy/containers/TermsCondtions'

const Illustration = styled.img`
	width: 90%;
`

export const LinkBlock = styled.span(p => ({
	textAlign: 'right',
	color: p.theme.color.primary,
	cursor: 'pointer',
	':hover': {
		textDecoration: 'underline',
	},
}))

const Login = () => {
	const { t, i18n } = useTranslation()
	const email = useSelector(AuthDuc.selectors.userSignInEmail)
	const { isMobile, isTablet } = useSelector(AppDuc.selectors.detection)
	const dispatch = useDispatch()
	const validationSchema = yup.object().shape({
		email: yup.string().required(t('login.loginUsername')),
		password: yup.string().required(t('validation.passwordRequired')),
		recaptcha: yup.string().required(t('validation.recaptchaReq')),
	})

	const [language, setLanguage] = useState()
	const [termsStatus, setTermsStatus] = useState(false)
	const [resetCaptcha, setResetCaptcha] = useState(getRandomNumber())

	useEffect(() => {
		const cookieString = document.cookie
		const cookies = cookieString.split(';')

		// Find the cookie that contains I18N_LANGUAGE
		const i18nLanguageCookie = cookies.find(cookie =>
			cookie.includes('I18N_LANGUAGE')
		)

		// Extract the value of I18N_LANGUAGE
		if (i18nLanguageCookie) {
			const keyValue = i18nLanguageCookie.split('=')
			setLanguage(keyValue[1])
		}
	}, [])

	useEffect(() => {
		if (language) {
			i18n.changeLanguage(language)
		}
	}, [i18n, language])

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues: {
			email,
			password: '',
			recaptcha:
				featureFlags.enableLoginCaptcha && !isIframeForTeamsApp()
					? ''
					: 'disabled',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			_values.enableLoginCaptcha =
				featureFlags.enableLoginCaptcha && !isIframeForTeamsApp()
			setResetCaptcha(getRandomNumber())
			dispatch(
				AuthDuc.creators.loginUser(_values, {
					setSubmitting,
				})
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.email

	const refreshAfterAInterval = () => {
		setTimeout(() => {
			window.location.reload()
		}, 2000)
	}

	return (
		<Box>
			<Modal
				heading={t('onboarding.terms&Cond')}
				icon={DibizIcon}
				show={termsStatus}
				boxModal
				body={<TermsCondtions />}
				confirmlabel={t('onboarding.agree')}
				size="large"
				onClose={() => {
					setTermsStatus(false)
				}}
				onConfirm={() => {
					setTermsStatus(false)
				}}
			/>
			<Box
				style={{
					width: '100vw',
					height: '100vh',
					background: ' #1D285D',
					overflowY: 'scroll',
				}}
			>
				<Spacer size={70} />
				<Box>
					<Box row padding={10} justifyContent="center">
						{!isMobile && !isTablet && (
							<Box>
								<Box
									padding={10}
									style={{
										background: theme.color.white,
										borderRadius: '4px',
										width: '180px',
									}}
								>
									<IconWrapper width={160} height={60}>
										<Icon glyph={DibizIcon} />
									</IconWrapper>
								</Box>
								<Spacer size={40} />
								<H2
									style={{
										fontSize: '40px',
										color: theme.color.white,
										maxWidth: '700px',
										lineHeight: '52px',
									}}
								>
									{t('login.welcome')}
								</H2>
								<Spacer size={20} />

								<SmallText
									color="#7D8AC7"
									fontSize="24px"
									style={{
										maxWidth: '700px',
									}}
								>
									{t('login.welcomeTagline')}
								</SmallText>
								<Spacer size={30} />
								<Box alignItems="baseline">
									<Illustration
										src="/images/DIBIZ_Marketplace.svg"
										alt="dibiz"
									/>
								</Box>
								<Spacer size={50} />

								<Box row alignItems="center">
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'terms-conditions',
													}
												)
											)
										}
										label="Terms & Conditions"
									/>
									<Spacer size={20} />
									<ButtonWithNoBorder
										type="submit"
										extendStyles={{
											color: '#ABB4DB',
											width: 'fit-content',
											textDecoration: 'none',
											padding: '0',
										}}
										onClick={() =>
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types.POLICY,
													{
														action:
															'privacy-policy',
													}
												)
											)
										}
										label={t('login.privacy')}
									/>
									<Spacer size={20} />
									<Box
										style={{
											fontSize: 12,
											color: '#ABB4DB',
											width: 'fit-content',
										}}
									>
										DIBIZ © {new Date().getFullYear()},{' '}
										{getAppVersion()} (Beta)
									</Box>
								</Box>
							</Box>
						)}
						<Box
							style={{
								width: '524px',
								height: 'fit-content',
								borderRadius: '10px',
								background: theme.color.white,
								boxShadow: '0px 3px 6px #00000029',
							}}
						>
							<Box
								style={{
									position: 'relative',
									top: isMobile || isTablet ? '5%' : '0%',
									width: '100%',
									height: 'inherit',
									alignSelf: 'center',
									padding:
										isMobile || isTablet
											? '19px 24px'
											: '19px 27px',
									zIndex: 1,
								}}
							>
								{(isMobile || isTablet) && (
									<Box
										padding="15px 0px"
										style={{
											background: theme.color.white,
											borderRadius: '4px',
										}}
									>
										<IconWrapper width={160} height={60}>
											<Icon glyph={DibizIcon} />
										</IconWrapper>
									</Box>
								)}
								<Box
									style={{
										padding:
											isMobile || isTablet
												? '15px 0px'
												: '15px 30px',
									}}
								>
									<H2
										style={{
											fontSize: '24px',
											color: '#2D2D2D',
										}}
									>
										{t('login.signIn')}
									</H2>
									<Spacer
										size={isMobile || isTablet ? 28 : 40}
									/>

									<form onSubmit={handleSubmit}>
										<Box
											style={{
												width: '100%',
											}}
										>
											<Box
												style={{
													width: '100%',
												}}
											>
												<Label
													style={{
														fontSize: '14px',
														color: '#555454',
													}}
												>
													{t(
														'login.enterRegisteredEmail'
													)}
												</Label>
												<Spacer size={12} />
												<Input
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													error={
														touched.email &&
														errors.email
													}
													name="email"
													type="text"
													extendStyles={{
														border:
															'1px solid #ACB7CD',
														borderRadius: '4px',
														opacity: 1,
													}}
												/>
											</Box>

											<Box
												row
												alignItems="baseline"
												justifyContent="space-between"
												style={{
													paddingBottom: '12px',
												}}
											>
												<Label
													style={{
														fontSize: '14px',
														color: '#555454',
													}}
												>
													{t('login.enterPassword')}
												</Label>
											</Box>
											<PasswordInput
												name="password"
												value={values.password}
												type="password"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.password &&
													errors.password
												}
												extendStyles={{
													border: '1px solid #ACB7CD',
													borderRadius: '4px',
													opacity: 1,
												}}
											/>
											{featureFlags.enableLoginCaptcha &&
												!isIframeForTeamsApp() && (
													<div key={resetCaptcha}>
														<Turnstile
															id="dibiz-captcha"
															refreshExpired="manual"
															sitekey={getRecaptchaKey()}
															onVerify={response => {
																setFieldValue(
																	'recaptcha',
																	response ||
																		''
																)
															}}
															onExpire={() => {
																if (
																	navigator.onLine
																) {
																	setResetCaptcha(
																		getRandomNumber()
																	)
																} else {
																	refreshAfterAInterval()
																}
															}}
															onError={() => {
																refreshAfterAInterval()
															}}
														/>
													</div>
												)}

											<Spacer size={20} />

											<SmallText
												color="#82868E"
												fontSize="14px"
											>
												{t('login.bySigningIn')}
												&nbsp;
												<SmallText
													style={{
														cursor: 'pointer',
														border: 'none',
														textDecoration: 'none',
														color:
															theme.color.blue4,
													}}
													onClick={() =>
														setTermsStatus(true)
													}
												>
													{t('login.terms&Policy')}
												</SmallText>
											</SmallText>

											<Spacer size={20} />

											<Button
												isLoading={isSubmitting}
												disabled={disableCTA}
												type="submit"
												primary
												label={t('login.signIn')}
												style={{
													paddingBottom: '50px',
													width: '100%',
												}}
											/>

											<Spacer size={20} />
										</Box>
									</form>
									<Spacer size={20} />
									{!isIframeForTeamsApp() && (
										<>
											<Box center>
												<LinkBlock
													style={{
														color:
															theme.color.blue4,
														fontSize:
															theme.fontSize.xl,
													}}
													onClick={() =>
														dispatch(
															MainRouteDuc.creators.switchPage(
																MainRouteDuc
																	.types.AUTH,
																{
																	action:
																		'forgot-password',
																}
															)
														)
													}
												>
													{t('login.forgotPassword')}
												</LinkBlock>
											</Box>
											<Spacer size={20} />
											<Box
												row={!isMobile && !isTablet}
												justifyContent="flex-end"
												alignItems="center"
											>
												<Label color="#A3ADDB">
													{t('login.noAccount')}
												</Label>
												<Spacer size={32} />
												<Button
													label={t(
														'onboarding.register'
													)}
													outline
													extendStyles={{
														color: '#3F56C4',
														backgroundColor:
															'transparent',
														border:
															'1px solid #3F56C4',
													}}
													onClick={() =>
														dispatch(
															MainRouteDuc.creators.redirect(
																MainRouteDuc
																	.types.AUTH,
																{
																	action:
																		'signup',
																}
															)
														)
													}
												/>
												<Spacer size={32} />
											</Box>
										</>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default Login
