import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DibizIcon from 'ui-lib/icons/dibiz-full-logo-transparent.svg'
import LeftArrow from 'ui-lib/icons/left-arrow.svg'

import styled from 'styled-components'
import theme from 'ui-lib/utils/base-theme'
import { H2 } from 'ui-lib/components/Typography'
import { MainRouteDuc } from 'ui-auth-app/routes/duc'
import { Title } from 'ui-auth-app/shared/components/Title'

import { Spacer } from 'ui-lib/utils/Spacer'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { NotFoundBlock } from 'ui-auth-app/modules/App/components/Error'
import { PolicyDuc } from './duc'
import PrivacyPolicy from './containers/PrivacyPolicy'
import TermsCondtions from './containers/TermsCondtions'

const LogoSearch = styled.div({
	height: '96px',
	display: 'flex',
	flexDirection: 'row',
	padding: '24px',
	background: theme.color.grey2,
	alignItems: 'center',
	justifyContent: 'space-between',
})

const modulesMap = {
	'privacy-policy': PrivacyPolicy,
	'terms-conditions': TermsCondtions,
}
const FILTERS_STATUS_CONFIG = [
	{
		code: 'terms-conditions',
		title: 'Terms & Conditions',
	},
	{
		code: 'privacy-policy',
		title: 'Privacy',
	},
]

// eslint-disable-next-line no-unused-vars
const getTabComponents = ({ activeModule }) => ({ name }) => () => {
	const Component = modulesMap[activeModule] || NotFoundBlock

	return (
		<Box padding={48}>
			<Component />
		</Box>
	)
}

const Policy = () => {
	const activeModule = useSelector(PolicyDuc.selectors.activeModule)
	const dispatch = useDispatch()

	const getTabs = useCallback(() => {
		return FILTERS_STATUS_CONFIG.map(({ code, title }) => ({
			name: code,
			title,
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [])

	// if we are here, we have a submodule, route it to the module
	const handleChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open_document') {
				const page =
					meta.currentPage === 'terms-conditions'
						? 'terms-conditions'
						: 'privacy-policy'
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.POLICY,
						{
							action: page,
						}
					)
				)
			}
		},
		[dispatch]
	)

	return (
		<Box>
			<LogoSearch>
				<Box row width={500} alignItems="center">
					<IconWrapper width={144} height={50}>
						<Icon glyph={DibizIcon} />
					</IconWrapper>
					<Spacer size={56} />
					<H2 color={theme.color.accent2}>Terms & Privacy</H2>
				</Box>

				<Title
					title="Back To Login"
					icon={LeftArrow}
					small
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.redirect(
								MainRouteDuc.types.AUTH,
								{
									action: 'sign-in',
								}
							)
						)
					}
				/>
			</LogoSearch>
			<Box>
				<Tabs
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						activeModule,
						onChange: handleChange,
					})}
					activeTabIndex={activeModule === 'terms-conditions' ? 0 : 1}
					onChange={({ name }) =>
						handleChange('open_document', {
							currentPage: name,
						})
					}
					noshadow
				/>
			</Box>
		</Box>
	)
}

export default Policy
