/* @flow */
import React from 'react'
import type { Node } from 'react'
import styled from 'styled-components'
import Icon404 from '../../icons/404.svg'
import Icon500 from '../../icons/500.svg'
import Icon401 from '../../icons/401.svg'
import IconEmpty from '../../icons/Empty.svg'
import IconOffline from '../../icons/offline.svg'
import NotVerified from '../../icons/not-verified.svg'
import ComingSoon from '../../icons/coming-soon.svg'
import { Icon } from '../../icons/components/Icon'
import { Box } from '../../utils/Box'
import { ButtonWithNoBorder } from '../Button'
import Theme from '../../utils/base-theme'

const ErrorCode = styled.div(({ theme, color, is500, is401 }) => ({
	color: color || theme.color.primary,
	fontSize: theme.fontSize.xxxxl,
	...theme.regularBaseFont,
	marginBottom: 30,
	marginTop: 0,
	...((is401 || is500) && { marginTop: 15 }),
}))

const IconWrapper = styled.div(p => ({
	width: p.small ? 150 : 250,
	height: p.small ? 150 : 250,
	...(p.is500 && { marginLeft: -25 }),
}))

const NewIconWrapper = styled.div`
	width: 350px;
	height: 350px;
	@media (max-height: 680px), (max-width: 1024px) {
		width: 250px;
		height: 250px;
	}
`

const Message = styled.span(p => ({
	fontSize: p.fontSize || Theme.fontSize.l,
	textAlign: 'center',
	color: p.color || p.theme.color.grey7,
}))

const NewTitleMessage = styled.span`
	font-size: 32px;
	text-align: center;
	color: ${Theme.color.accent2} || ${Theme.color.grey7};
	@media (max-height: 700px), (max-width: 1024px) {
		width: 750px;
		font-size: 20px;
	}
`

const NewSubtitleMessage = styled.span`
	font-size: 24px;
	text-align: center;
	color: ${Theme.color.accent2} || ${Theme.color.grey7};
	@media (max-height: 700px), (max-width: 1024px) {
		font-size: 14px;
	}
`

const defaultMessage = 'Uh oh! Something Went Wrong. We are fixing this asap.'

const STATUS_ICON_MAP = {
	'404': Icon404,
	'500': Icon500,
	'401': Icon401,
	offline: IconOffline,
	empty: IconEmpty,
	'not-verified': NotVerified,
	'coming-soon': ComingSoon,
}

type Props = {
	status: number | string,
	message?: string,
	customIcon?: string | Node,
	isError?: boolean,
	messageType?: 'error' | 'info' | 'warn',
	buttonProps?: {
		label: string,
		onClick: () => void,
	},
	hideButton?: boolean,
	small?: boolean,
	title?: string,
	subtitle?: string,
}

const COLOR_MAP = {
	error: Theme.color.error,
	info: Theme.color.grey7,
	warn: Theme.color.warn,
}

const MESSAGE_MAP = {
	'500': defaultMessage,
	'401': 'Sneaky! You are not authorized.',
	'404': "Oh My! We couldn't find this page",
	empty: 'Its so empty here',
}

const Wrapper = styled(Box)(p => ({
	backgroundColor: p.theme.color.grey0,
	padding: 25,
	width: '100%',
	height: '100%',
}))

export const ErrorBlock = ({
	status = 500,
	message,
	customIcon = null,
	messageType = '',
	buttonProps = {},
	hideButton,
	small,
}: Props) => (
	<Wrapper center>
		<IconWrapper is500={status === 500} small={small}>
			<Icon glyph={customIcon || STATUS_ICON_MAP[status.toString()]} />
		</IconWrapper>
		{status !== 'empty' && (
			<ErrorCode
				is500={status === 500}
				is401={status === 401}
				color={COLOR_MAP[messageType]}
			>
				{status}
			</ErrorCode>
		)}
		<Message color={COLOR_MAP[messageType]}>
			{message || MESSAGE_MAP[status] || defaultMessage}
		</Message>
		{!hideButton && (
			<ButtonWithNoBorder
				label={buttonProps.label}
				onClick={buttonProps.onClick}
				extendStyles={{ marginTop: 15 }}
			/>
		)}
	</Wrapper>
)

export const NewErrorBlock = ({ status, title, subtitle }: Props) => {
	return (
		<Wrapper center>
			<NewIconWrapper>
				<Icon glyph={STATUS_ICON_MAP[status.toString()]} />
			</NewIconWrapper>

			{title && (
				<NewTitleMessage style={{ margin: '60px 0 16px' }}>
					{title}
				</NewTitleMessage>
			)}
			{subtitle && <NewSubtitleMessage>{subtitle}</NewSubtitleMessage>}
		</Wrapper>
	)
}

ErrorBlock.defaultProps = {
	buttonProps: {
		label: 'Retry',
		onClick: () => {
			if (window) window.location.reload()
		},
	},
}
