import { deepFreeze } from 'grommet/utils'

const baseColors = {
	primary: 'rgb(38,59,151)',
	accent: '#8F9EFF',
	accent2: '#172B4D',
	accent3: '#CCE3FA',
	accent4: '#DDECFA',
	green: '#299F74',
	green1: '#26AD74',
	green2: '#579589',
	green3: '#DFF7F3',
	grey0: '#F8F8F8',
	grey1: '#f5f5f5',
	grey2: '#ebebeb',
	grey3: '#D2D2D2',
	grey4: '#CAD2DD',
	grey5: '#CCCCCC',
	grey6: '#828598',
	grey7: '#808080',
	grey8: '#4c4c4c',
	grey9: '#7B8088',
	grey10: '#9EA2A8',
	grey11: '#FAFAFA',
	grey12: '#ACB7CD',
	grey13: '#F4F5F7',
	grey14: '#727F9A',
	grey15: '#8D93A0',
	grey16: '#707070',
	grey17: '#E9ECF0',
	grey18: '#D1D2D4',
	grey19: '#EAEAEB',
	grey20: '#f5f7fc',
	grey21: '#777C86',
	grey22: '#EDF0F4',
	grey23: '#c9c9c9',
	error: '#cb4262',
	warn: '#dea64d',
	success: '#30b57d',
	neutral: '#005974',

	info: '#005974',
	white: '#fff',
	white1: '#F2F3F7',
	white3: '#EFF7FF',
	black: '#000',
	black1: '#08090C',
	black2: '#333333',
	black3: '#242845',
	black4: '#3A3D57',
	blue0: '#E2EEFF',
	blue1: '#5674E9',
	blue2: '#233EC0',
	blue3: '#366AB4',
	blue4: '#2777F6',
	blue5: '#3F56C4',
	blue6: '#3F56C43D',
	blue7: '#2874DF',
	blue8: '#3F57C6',
	blue9: '#949DC6',
	blue10: '#DEE2F5',
	blue11: '#A4ABDE',
	blue12: '#333F91',
	blue13: '#2F6FC7',
	blue14: '#1683DF',

	themeBlue1: '#A2C5F4',
	themeBlue2: '#1683DF',
	themeBlue3: '#D9DEE5',
	themeBlue4: '#ECF0F7',
	themeBlue5: '#FAFBFC',
	themeBlue6: '#D4DAF8',
	themeBlue7: '#c8d0fd',
	themeBlue8: '#bbc5fb',
	themeBlue9: '#b3b8ff',
	themeBlue10: '#a9a8f8',
	themeBlue11: '#93a3f2',
	themeBlue12: '#8497f1',
	themeBlue13: '#A1E6E6',
	themeBlue14: '#D0CCFA',
	themeBlue15: '#574D9B',
	themeGreen2: '#13835A',
	themeGreen3: '#EDFBF6',
	themeGreen4: '#158853',
	themeGreen5: '#C9F7C3',
	themeGreen6: '#228686',
	themeGreen7: '#008000',
	themeGreen8: '#e07616',
	themeYellow1: '#FFEED4',
	themeyellow2: '#F09112',

	themeGrey1: '#777C86',
	themeGrey2: '#EDF0F4',
	themeRed1: '#EA5858',
	themeRed2: '#EA9E52',
	themeRed3: '#D8672B',
	themeRed4: '#F8BFBF',
	themeLightRed1: '#FFF4E5',
	themeBrown1: '#BF3333',
	purple: '#480ca8',
	orange: '#E6684A',
	red1: '#D65C5C',
	themeDec_AuditReport: 'rgb(48,181,125)',
	themeDec_TradeMgr: 'rgb(48,64,181)',
	themeDec_Production: 'rgb(222,166,77)',
	themeDec_Partner: 'rgb(203,66,98)',
	themeDec_Quality: 'rgb(157,123,165)',
	themeDec_Products: 'rgb(134,107,207)',
	themeDec_Weighbridge: 'rgb(213,121,80)',
	themeDec_ProdTrace: 'rgb(21,159,170)',
	themeDec_Feedback: 'rgb(213,121,80)',
	themeDec_Broadcast: 'rgb(48,64,181)',
}

export const convertToRGBA = (rgb, a) => {
	const regRGB = /^rgb\(([0-9 ]+),([0-9 ]+),([0-9 ]+)\)$/

	const color = rgb.trim ? rgb.trim() : rgb
	if (!color) {
		return color
	}

	const groups = regRGB.exec(color)
	if (!groups || groups.length !== 4) {
		return color
	}

	return `rgba(${groups[1].trim()},${groups[2].trim()},${groups[3].trim()},${a})`
}

export const convertToRGB = rgba => {
	const regRGBA = /^rgba\(([0-9 ]+),([0-9 ]+),([0-9 ]+),([0-9 ]+\.[0-9 ]+)\)$/
	const color = rgba.trim ? rgba.trim() : rgba
	if (!color) {
		return color
	}

	const groups = regRGBA.exec(color)
	if (!groups || groups.length !== 5) {
		return color
	}

	return `rgb(${groups[1].trim()},${groups[2].trim()},${groups[3].trim()})`
}

const grommetTheme = {
	global: {
		active: {
			color: {
				dark: baseColors.white,
				light: baseColors.primary,
			},
		},
		focus: {
			border: {
				width: '2px',
				color: baseColors.accent,
			},
		},
		control: {
			border: {
				color: baseColors.primary,
			},
		},
		colors: {
			brand: baseColors.primary,
			accent: baseColors.accent,
			placeholder: baseColors.grey4,
			control: baseColors.primary,
		},
		input: {
			weight: 400,
			padding: '12px',
			border: {
				radius: '4px',
			},
		},
		drop: {
			extend: {
				border: '1px solid #fff',
			},
		},
		font: {
			name: 'Roboto',
			size: '16px',
			family: "'Roboto', Arial, sans-serif",
		},
	},
	text: {
		medium: {
			height: '32px',
		},
		extend: {
			fontSize: 'inherit',
		},
	},
	select: {
		icons: {
			margin: 'none',
		},
	},
	button: {
		color: {
			dark: baseColors.white,
			light: baseColors.primary,
		},
		primary: {
			// color: baseColors.primary,
			color: baseColors.blue5,
		},
		border: {
			radius: '4px',
			color: baseColors.blue5,
			width: '1px',
		},
	},
	radioButton: {
		hover: {
			border: {
				color: baseColors.accent,
			},
		},
		extend: ({ checked }) => `
				${checked &&
					`box-shadow: 0 0 2px 2px ${baseColors.accent}; border-color: ${baseColors.primary}`}
			`,
	},
	checkBox: {
		toggle: {
			extend: ({ checked }) => `
			${
				checked
					? `box-shadow: none; border-color: ${baseColors.primary}`
					: 'box-shadow: none'
			}
			`,
		},
		hover: {
			border: {
				color: baseColors.accent,
			},
		},
		border: {
			radius: '4px',
		},
	},
	layer: {
		border: {
			radius: '4px',
		},
	},
}

const theme = {
	orientationQueries: {
		portrait: '(orientation: portrait)',
		landscape: '(orientation: landscape)',
	},
	mediaQueries: {
		mobile: '(min-width: 320px) and (max-width: 767px)',
		tablet: '(min-width: 768px) and (max-width: 991px)',
		desktop: '(min-width: 1024px)',
	},
	color: baseColors,
	lineHeight: {
		xxl: 2,
		xl: 1.75,
		l: 1.5,
		m: 1,
		s: 0.5,
	},
	fontSize: {
		xxxxl: '30px',
		xxxl: '26px',
		xxl: '24px',
		xl: '18px',
		l: '16px',
		m: '14px',
		s: '14px',
		xs: '12px',
		xxs: '10px',
		xxxs: '8px',
	},
	fonts: {
		regular: {
			fontFamily: 'roboto',
			fontWeight: '400',
		},
		medium: {
			fontFamily: 'roboto',
			fontWeight: '500',
		},
		bold: {
			fontFamily: 'roboto',
			fontWeight: '700',
		},
	},
	letterSpacing: {
		xs: 1,
	},
	boxShadow: {
		thick: '0 0 20px 0 rgba(136,136,136,0.50)',
		thin: '0px 1px 4px rgba(0, 0, 0, 0.1)',
	},
	em: '1em',
	rem: '1rem',
	border: '1px solid #ccc',
	borderRadius: '2',
	arrowSize: '5',
	bodyBackground: '#ebebeb',
	zIndex: {
		bury: -1,
		level1: 1,
		level2: 2,
		level3: 3,
		level4: 4,
		level5: 5,
		extreme: 999,
	},
	pxScale: 8,
	...grommetTheme,
}

export default deepFreeze(theme)
