import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { H2, SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getAppVersion } from 'ui-auth-app/config'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { PasswordInput } from 'ui-lib/components/Input'
import * as yup from 'yup'
import { isEmptyObject } from 'ui-auth-app/utils/helpers'
import PasswordStrengthBar from 'react-password-strength-bar'

const SetPassword = () => {
	const { t } = useTranslation()
	const { query = {} } = useSelector(AuthDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { email, secret } = query

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.min(12, t('validation.passwordLength'))
			.max(64, t('validation.passwordMaxLength'))
			.required(t('validation.passwordRequired')),
		repeatPassword: yup
			.string()

			.oneOf([yup.ref('password'), null], 'Passwords must match'),
	})
	const dispatch = useDispatch()

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			password: '',
			repeatPassword: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const formValues = {
				..._values,
				email,
				secret,
			}
			dispatch(
				AuthDuc.creators.createPwd(
					formValues,
					t('common.newPswdCreateSuccess'),
					{ setSubmitting }
				)
			)
		},
	})
	const disableCTA = !isEmptyObject(errors) || !values.repeatPassword

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				background: ' #1D285D',
				overflowY: 'scroll',
			}}
			center
		>
			<Box center padding={8}>
				<H2
					style={{
						fontSize: '40px',
						color: theme.color.white,
						lineHeight: '52px',
						fontWeight: 'bold',
					}}
				>
					Set Password!
				</H2>
				<Spacer size={15} />
				<Box
					style={{
						width: isMobile ? 'auto' : '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<Spacer size={10} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '32px',
							lineHeight: '32px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						Wonderful!
					</SmallText>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							width: '500px',
							textAlign: 'center',
						}}
					>
						Your email has been verified.
					</SmallText>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						Now, let’s set your account password and sign in!
					</SmallText>
					<Spacer size={40} />

					<form onSubmit={handleSubmit}>
						<Box
							style={{
								width: isMobile ? 'auto' : '410px',
							}}
						>
							<PasswordInput
								name="password"
								value={values.password}
								type="password"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Set your password"
								error={touched.password && errors.password}
								extendStyles={{
									border: '1px solid #ACB7CD',
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
						<Box
							style={{
								width: isMobile ? 'auto' : '410px',
							}}
						>
							{/* <Label
								required
								style={{
									fontSize: '14px',
									color: '#555454',
								}}
							>
								Confirm your password
							</Label> */}
							<PasswordInput
								name="repeatPassword"
								value={values.repeatPassword}
								type="password"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Confirm your password"
								error={
									touched.repeatPassword &&
									errors.repeatPassword
								}
								extendStyles={{
									border: '1px solid #ACB7CD',
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>
						<Box
							style={{
								marginBottom: 10,
							}}
						>
							<PasswordStrengthBar
								password={values.password}
								minLength={12}
							/>
						</Box>
						<Box
							style={{
								width: '100%',
							}}
						>
							<Button
								isLoading={isSubmitting}
								disabled={disableCTA}
								type="submit"
								primary
								label={t('login.setPassword')}
								style={{
									width: '100%',
								}}
							/>
						</Box>
					</form>
				</Box>
			</Box>
			<Box
				row
				style={{
					width: '100%',
					position: 'absolute',
					bottom: 0,
					marginBottom: '1px',
				}}
				alignItems="center"
				justifyContent="center"
			>
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/terms-conditions', '_blank')}
					label="Terms & Conditions"
				/>
				<Spacer size={20} />
				<ButtonWithNoBorder
					type="submit"
					extendStyles={{
						color: '#ABB4DB',
						width: 'fit-content',
						textDecoration: 'none',
						padding: '0',
					}}
					onClick={() => window.open('/privacy-policy', '_blank')}
					label={t('login.privacy')}
				/>
				<Spacer size={20} />

				<SmallText color="#ABB4DB" fontSize="14px">
					{`Version ${getAppVersion()}`}
				</SmallText>
			</Box>
		</Box>
	)
}

export { SetPassword }
