import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'

export default async (dispatch, getState, { action }) => {
	// const state = getState()
	// Inject the saga here. If it wasn't already
	// extra.getSagaInjectors().injectSaga('listing', { saga })

	const payload = action.payload || {}
	if (payload.action === 'forgot-password') {
		dispatch(AuthDuc.creators.flushForgotPasswordState())
	}
	if (payload.action === 'reset-password') {
		dispatch(AuthDuc.creators.flushResetPasswordState())
	}
}
