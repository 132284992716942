import React from 'react'
import styled from 'styled-components'
import { P, H2 } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { getInfoAddressLink } from 'ui-auth-app/config'

const Wrapper = styled.div({ lineHeight: 2, textAlign: 'justify' })

const CTAWrapper = styled.span(p => ({
	...p.theme.fonts.bold,
	color: theme.color.black,
	textAlign: 'justify',
}))

const Title = styled.div(p => ({
	...p.theme.fonts.bold,
	fontSize: p.small ? p.theme.fontSize.m : p.theme.fontSize.xl,
	paddingBottom: 4,
	color: p.theme.color.primary,
}))

export const UnOrderedList = styled.ul(p => ({
	overflow: 'hidden',
	paddingLeft: p.padding ? '48px' : '28px',
}))

export const ListItem = styled.li(p => ({
	margin: 'none',
	fontSize: p.bold ? p.theme.fontSize.xl : p.theme.fontSize.m,
	...(p.bold ? p.theme.fonts.bold : p.theme.fonts.regular),
	color: p.bold ? p.theme.color.primary : p.theme.color.black,
}))

const LinkBlock = styled.span(p => ({
	color: p.theme.color.primary,
	cursor: 'pointer',
	paddingLeft: '4px',
	':hover': {
		textDecoration: 'underline',
	},
}))

const Text = styled.p({
	paddingLeft: '52px',
})

function PrivacyPolicy() {
	return (
		<Wrapper>
			<H2>Introduction</H2>
			<Spacer size={12} />
			<P>
				This Privacy Policy describes Our policies and procedures on the
				collection, use and disclosure of Your information when You use
				the Service and tells You about Your privacy rights and how the
				law protects You.  It governs data collection, processing and
				usage in compliance with the Personal Data Protection Act of
				Malaysia (“PDPA”). 
			</P>
			<Spacer size={12} />
			<P>
				We use Your Personal data to provide and improve the Service. By
				using the Service, You agree to the collection and use of
				information in accordance with this Privacy Policy.
			</P>

			<Spacer size={16} />
			<H2>Interpretation and Definitions </H2>
			<Spacer size={16} />
			<Title>Interpretation</Title>
			<P>
				The words of which the initial letter is capitalized have
				meanings defined under the following conditions.
				<Spacer size={4} />
				The following definitions shall have the same meaning regardless
				of whether they appear in singular or in plural.
			</P>
			<Spacer size={16} />
			<Title>Definitions</Title>
			<P>For the purposes of this Privacy Policy: </P>
			<Text>
				<CTAWrapper>You</CTAWrapper> means the individual accessing or
				using the Service, or the company, or other legal entity on
				behalf of which such individual is accessing or using the
				Service, as applicable.
			</Text>
			<Text>
				<CTAWrapper>Company</CTAWrapper> (referred to as either "the
				Company", "We", "Us" or "Our" in this Agreement) refers to DIBIZ
				Pte Ltd, Malaysia.
			</Text>
			<UnOrderedList>
				<ListItem>
					<CTAWrapper>Affiliate</CTAWrapper> means an entity that
					controls, is controlled by or is under common control with a
					party, where "control" means ownership of 50% or more of the
					shares, equity interest or other securities entitled to vote
					for election of directors or other managing authority.
				</ListItem>

				<ListItem>
					<CTAWrapper>Account</CTAWrapper> means a unique account
					created for You to access our Service or parts of our
					Service.
				</ListItem>

				<ListItem>
					<CTAWrapper>Website</CTAWrapper> refers to DIBIZ, accessible
					from
					<LinkBlock
						onClick={() => {
							window.location.replace('https://dibizglobal.com')
						}}
					>
						https://dibizglobal.com/
					</LinkBlock>
				</ListItem>

				<ListItem>
					<CTAWrapper>Service</CTAWrapper> refers to the Website and
					customer acquisition, Supply Chain and sustainability
					platform
				</ListItem>

				<ListItem>
					<CTAWrapper>Country</CTAWrapper> refers to: Malaysia
				</ListItem>

				<ListItem>
					<CTAWrapper>Service Provider</CTAWrapper> means any natural
					or legal person who processes the data on behalf of the
					Company. It refers to third-party companies or individuals
					employed by the Company to facilitate the Service, to
					provide the Service on behalf of the Company, to perform
					services related to the Service or to assist the Company in
					analyzing how the Service is used.
				</ListItem>

				<ListItem>
					<CTAWrapper>Personal Data</CTAWrapper> is any information
					that relates to an identified or identifiable individual.
				</ListItem>

				<ListItem>
					<CTAWrapper>Cookies</CTAWrapper> are small files that are
					placed on Your computer, mobile device or any other device
					by a website, containing the details of Your browsing
					history on that website among its many uses.
				</ListItem>

				<ListItem>
					<CTAWrapper>Usage Data</CTAWrapper> refers to data collected
					automatically, either generated by the use of the Service or
					from the Service infrastructure itself (for example, the
					duration of a page visit).
				</ListItem>

				<ListItem>
					<CTAWrapper>Supply Chain Data</CTAWrapper> refers to the
					data collected from documents like Purchase orders, Invoice,
					Delivery Copy etc.
				</ListItem>
			</UnOrderedList>

			<Spacer size={24} />
			<H2>Collecting and Using Your Personal Data</H2>
			<Title>Types of Data Collected</Title>
			<Title small>Personal Data</Title>
			<P>
				While using Our Service, We may ask You to provide Us with
				certain personally identifiable information that can be used to
				contact or identify You. Personally identifiable information may
				include, but is not limited to:
			</P>
			<UnOrderedList>
				<ListItem>Email address</ListItem>
				<ListItem>First name and last name</ListItem>
				<ListItem>Phone number</ListItem>
				<ListItem>
					Address, State, Province, ZIP/Postal code, City
				</ListItem>
				<ListItem>Usage Data</ListItem>
			</UnOrderedList>
			<P>
				When you contact us in connection with a request for information
				(offline or online), to provide you with support, we collect
				information necessary to fulfill your request, to grant you
				access to the product or service, to provide you with support
				and to be able to contact you. For instance, we collect your
				name and contact information, details about your request and we
				may include client satisfaction survey information. We retain
				such information for administrative purposes, defending our
				rights, and in connection with our relationship with you.
				<Spacer size={8} />
				Most information collected about you comes from our direct
				interaction with you. When you register for an event we may
				collect information and during an event, such as participation
				in sessions, survey results. We combine the personal information
				we collect to develop aggregate analysis and business
				intelligence for conducting our business and for marketing
				purposes.
			</P>
			<Spacer size={16} />
			<Title small>Usage Data</Title>
			<P>
				Usage Data is collected automatically when using the Service.
				<Spacer size={8} />
				Usage Data may include information such as Your Device's
				Internet Protocol address (e.g. IP address), browser type,
				browser version, the pages of our Service that You visit, the
				time and date of Your visit, the time spent on those pages,
				unique device identifiers and other diagnostic data.
				<Spacer size={8} />
				When You access the Service by or through a mobile device, We
				may collect certain information automatically, including, but
				not limited to, the type of mobile device You use, Your mobile
				device unique ID, the IP address of Your mobile device, Your
				mobile operating system, the type of mobile Internet browser You
				use, unique device identifiers and other diagnostic data.
				<Spacer size={8} />
				We may also collect information that Your browser sends whenever
				You visit our Service or when You access the Service by or
				through a mobile device.
			</P>
			<Spacer size={16} />
			<Title small>Supply Chain Data</Title>
			<P>
				Unpriced Supply Chain data from Purchase Order, Invoice,
				Transportation Copies, Payment copies etc are hashed (one-way
				encryption) and stored in Blockchain Ledgers on Nodes available
				with other authorized Clients of DIBIZ.
			</P>
			<Spacer size={16} />
			<Title small>Tracking Technologies and Cookies</Title>
			<P>
				We use Cookies and similar tracking technologies to track the
				activity on Our Service and store certain information. Tracking
				technologies used are beacons, tags, and scripts to collect and
				track information and to improve and analyze Our Service.
				<Spacer size={8} />
				You can instruct Your browser to refuse all Cookies or to
				indicate when a Cookie is being sent. However, if You do not
				accept Cookies, You may not be able to use some parts of our
				Service.
				<Spacer size={8} />
				Cookies can be "Persistent" or "Session" Cookies. Persistent
				Cookies remain on your personal computer or mobile device when
				You go offline, while Session Cookies are deleted as soon as You
				close your web browser.
			</P>
			<Spacer size={8} />
			<P>
				We use both session and persistent Cookies for the purposes set
				out below:
			</P>
			<Text>
				<CTAWrapper>Necessary / Essential Cookies</CTAWrapper>
				<P>Type: Session Cookies</P>
				<P>Administered by: Us</P>
				<P>
					Purpose: These Cookies are essential to provide You with
					services available through the Website and to enable You to
					use some of its features. They help to authenticate users
					and prevent fraudulent use of user accounts. Without these
					Cookies, the services that You have asked for cannot be
					provided, and We only use these Cookies to provide You with
					those services.
				</P>
				<CTAWrapper>
					Cookies Policy / Notice Acceptance Cookies
				</CTAWrapper>
				<P>Type: Persistent Cookies</P>
				<P>Administered by: Us</P>
				<P>
					Purpose: These Cookies identify if users have accepted the
					use of cookies on the Website.
				</P>
				<CTAWrapper>Functionality Cookies</CTAWrapper>
				<P>Type: Persistent Cookies</P>
				<P>Administered by: Us</P>
				<P>
					Purpose: These Cookies allow us to remember choices You make
					when You use the Website, such as remembering your login
					details or language preference. The purpose of these Cookies
					is to provide You with a more personal experience and to
					avoid You having to re-enter your preferences every time You
					use the Website.
				</P>
			</Text>
			<Spacer size={8} />
			<P>
				For more information about the cookies we use and your choices
				regarding cookies, please visit our Cookies Policy.
			</P>

			<Spacer size={16} />
			<Title>Use of Your Personal Data</Title>
			<P>The Company may use Personal Data for the following purposes:</P>
			<UnOrderedList>
				<ListItem>
					<CTAWrapper>
						To provide and maintain our Service,
					</CTAWrapper>
					including to monitor the usage of our Service.
				</ListItem>

				<ListItem>
					<CTAWrapper>To manage Your Account:</CTAWrapper> to manage
					Your registration as a user of the Service. The Personal
					Data You provide can give You access to different
					functionalities of the Service that are available to You as
					a registered user.
				</ListItem>
				<ListItem>
					<CTAWrapper>For the performance of a contract:</CTAWrapper>
					the development, compliance and undertaking of the purchase
					contract for the products, items or services You have
					purchased or of any other contract with Us through the
					Service.
				</ListItem>

				<ListItem>
					<CTAWrapper>To contact You:</CTAWrapper> To contact You by
					email, telephone calls, SMS, or other equivalent forms of
					electronic communication, such as a mobile application's
					push notifications regarding updates or informative
					communications related to the functionalities, products or
					contracted services, including the security updates, when
					necessary or reasonable for their implementation.
				</ListItem>
				<ListItem>
					<CTAWrapper>To provide You</CTAWrapper> with news, special
					offers and general information about other goods, services
					and events which we offer that are similar to those that you
					have already purchased or enquired about unless You have
					opted not to receive such information.
				</ListItem>
				<ListItem>
					<CTAWrapper>To manage Your requests:</CTAWrapper> To attend
					and manage Your requests to Us.
				</ListItem>
			</UnOrderedList>

			<Spacer size={16} />
			<Title>Retention of Your Personal Data</Title>
			<P>
				The Company will retain Your Personal Data only for as long as
				is necessary for the purposes set out in this Privacy Policy. We
				will retain and use Your Personal Data to the extent necessary
				to comply with our legal obligations (for example, if we are
				required to retain your data to comply with applicable laws),
				resolve disputes, and enforce our legal agreements and policies.
				<Spacer size={8} />
				The Company will also retain Usage Data for internal analysis
				purposes. Usage Data is generally retained for a shorter period
				of time, except when this data is used to strengthen the
				security or to improve the functionality of Our Service, or We
				are legally obligated to retain this data for longer time
				periods.
			</P>

			<Spacer size={16} />
			<Title>Transfer of Your Personal Data</Title>
			<P>
				Your information, including Personal Data, is processed at the
				Company's operating offices and in any other places where the
				parties involved in the processing are located. It means that
				this information may be transferred to — and maintained on —
				computers located outside of Your state, province, country or
				other governmental jurisdiction where the data protection laws
				may differ than those from Your jurisdiction.
				<Spacer size={8} />
				Your consent to this Privacy Policy followed by Your submission
				of such information represents Your agreement to that transfer.
				<Spacer size={8} />
				The Company will take all steps reasonably necessary to ensure
				that Your data is treated securely and in accordance with this
				Privacy Policy and no transfer of Your Personal Data will take
				place to an organization or a country unless there are adequate
				controls in place including the security of Your data and other
				personal information.
			</P>
			<Spacer size={16} />
			<Title>Disclosure of Your Personal Data</Title>

			<Title small>Business Transactions</Title>
			<P>
				If the Company is involved in a merger, acquisition or asset
				sale, Your Personal Data may be transferred. We will provide
				notice before Your Personal Data is transferred and becomes
				subject to a different Privacy Policy.
			</P>

			<Spacer size={16} />
			<Title small>Law enforcement</Title>
			<P>
				The Company may be required to disclose Your Personal Data if
				required to do so by law or in response to valid requests by
				public authorities (e.g. a court or a government agency).
			</P>

			<Spacer size={16} />
			<Title>Security of Your Personal Data</Title>
			<P>
				The security of Your Personal Data is important to Us, but
				remember that no method of transmission over the Internet, or
				method of electronic storage is 100% secure. While We strive to
				use commercially acceptable means to protect Your Personal Data,
				We cannot guarantee its absolute security. Once we have received
				your information, we will use strict procedures and security
				features to try to prevent unauthorized access.
			</P>

			<Spacer size={24} />
			<H2>Disclosure of Personal Information</H2>
			<P>
				Company will disclose or share your Personal Information,
				without notice, only if required to do so by law (a) comply with
				any legal requirements or comply with legal process served on
				Company or the Website; (b) protect and defend the rights or
				property of the Company; and (c) act under exigent circumstances
				to protect the personal safety of users of Dibizglobal.com, or
				the general public.
				<Spacer size={4} />
				We may disclose your personal information to third parties: (a)
				in the event that we sell or buy any business or assets, in
				which case we may disclose your personal data to the prospective
				seller or buyer of such business or assets; and (b) if
				Dibizglobal.com or substantially all of its assets are acquired
				by a third party, in which case personal data held by it about
				its customers will be one of the transferred assets
			</P>
			<Spacer size={16} />
			<H2>
				Access to, Updating, and Non-Use of Your Personal Information
			</H2>
			<P>
				Subject to the exceptions referred to in section 21(2) of PDPA,
				you have the right to request a copy of the information that we
				hold about you. If you would like a copy of some or all of your
				personal information, please send an email to
				<LinkBlock
					onClick={() => {
						window.location.replace(
							`mailto:${getInfoAddressLink()}`
						)
					}}
				>
					{`${getInfoAddressLink()}.`}
				</LinkBlock>
				<Spacer size={4} />
				We want to ensure that your Personal Information is accurate and
				up to date. If any of the information that you have provided to
				the Company changes, for example if you change your email
				address, name or contact number, please let us know the correct
				details by sending an email to
				<LinkBlock
					onClick={() => {
						window.location.replace(
							`mailto:${getInfoAddressLink()}`
						)
					}}
				>
					{`${getInfoAddressLink()}. `}
				</LinkBlock>
				You may ask us, or we may ask you, to correct information you or
				we think is inaccurate, and you may also ask us to remove
				information which is inaccurate.
				<Spacer size={4} />
				You have the right to ask us not to collect, use, process, or
				disclose your Personal Information in any of the manner
				described herein. We will usually inform you (before collecting
				your Personal Information) if we intend to use your Personal
				Information for such purposes or if we intend to disclose your
				Personal Information to any third party for such purposes. You
				can give us notice of your intention to halt the collection,
				use, processing, or disclosure of your Personal Information at
				any time by contacting us at {getInfoAddressLink()}.
			</P>
			<Spacer size={16} />
			<H2>Links to Other Websites</H2>
			<P>
				Our Service may contain links to other websites that are not
				operated by Us. If You click on a third party link, You will be
				directed to that third party's site. We strongly advise You to
				review the Privacy Policy of every site You visit.
				<Spacer size={4} />
				We have no control over and assume no responsibility for the
				content, privacy policies or practices of any third-party sites
				or services.
			</P>
			<Spacer size={16} />
			<H2>Changes to this Privacy Policy</H2>
			<P>
				We may update our Privacy Policy from time to time. We will
				notify You of any changes by posting the new Privacy Policy on
				this page.
				<Spacer size={4} />
				We will let You know via email and/or a prominent notice on Our
				Service, prior to the change becoming effective and update the
				"Last updated" date at the top of this Privacy Policy.
				<Spacer size={4} />
				You are advised to review this Privacy Policy periodically for
				any changes. Changes to this Privacy Policy are effective when
				they are posted on this page.
			</P>
			<Spacer size={16} />
			<H2>Contact Us</H2>
			<P>
				If you have any questions about this Privacy Policy, You can
				contact us:
			</P>
			<UnOrderedList>
				<ListItem>
					By visiting this page on our website:
					<LinkBlock
						onClick={() => {
							window.location.replace('https://dibizglobal.com')
						}}
					>
						https://dibizglobal.com/
					</LinkBlock>
				</ListItem>
			</UnOrderedList>
		</Wrapper>
	)
}

export default PrivacyPolicy
