import React from 'react'
import { useSelector } from 'react-redux'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { NotFoundBlock } from 'ui-auth-app/modules/App/components/Error'
import Login from 'ui-auth-app/modules/Auth/containers/Login'
import SignUp from 'ui-auth-app/modules/Auth/containers/Signup'
import SignupDetail from 'ui-auth-app/modules/Auth/containers/SignupDetail'
import TwoStepVerification from 'ui-auth-app/modules/Auth/containers/TwoStepVerification'
import ForgotPassword from 'ui-auth-app/modules/Auth/containers/ForgotPassword'
import ResetPassword from 'ui-auth-app/modules/Auth/containers/ResetPassword'
import VerifyEmail from 'ui-auth-app/modules/Auth/containers/VerifyEmail'
import { SetPassword } from 'ui-auth-app/modules/Auth/containers/SetPassword'

const ComponentsMap = {
	'sign-in': Login,
	signup: SignUp,
	'signup-detail': SignupDetail,
	'forgot-password': ForgotPassword,
	'reset-password': ResetPassword,
	'set-password': SetPassword,
	'two-step-verification': TwoStepVerification,
	'verify-email': VerifyEmail,
}

const AuthCore = () => {
	const { payload = {} } = useSelector(AuthDuc.selectors.location)
	const { action } = payload
	const CoreComponent = ComponentsMap[action] || NotFoundBlock

	return <CoreComponent />
}

export default AuthCore
