import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'ui-lib/utils/Box'

const RenderMessage = ({ message = '', isValid = false }) => (
	<Box
		style={{
			width: '100%',
			paddingBottom: '10px',
			color: isValid ? 'green' : 'gray',
		}}
	>
		{message}
	</Box>
)

export const PasswordValidationMessage = ({
	password = '',
	maxLength = 8,
	upperCaseRequired = true,
	lowercaseRequired = true,
	specialCharRequired = true,
}) => {
	const { t } = useTranslation()
	const hasUpper = upperCaseRequired ? password.match(/[A-Z]/) : true

	const hasLower = lowercaseRequired ? password.match(/[a-z]/) : true

	const hasSpecial = specialCharRequired
		? password.match(/[\d`~!@#$%^&*()+=|;:'",.<>/?-]/)
		: true

	const hasMinLength = maxLength ? password.length > maxLength : true

	return (
		<Box style={{ width: '100%' }}>
			<RenderMessage
				message={`${t('login.atLeast')} ${maxLength} ${t(
					'login.characters'
				)}`}
				isValid={hasMinLength}
			/>
			{upperCaseRequired && (
				<RenderMessage
					message={t('login.uppercaseChar')}
					isValid={hasUpper}
				/>
			)}
			{lowercaseRequired && (
				<RenderMessage
					message={t('login.lowercaseChar')}
					isValid={hasLower}
				/>
			)}
			{specialCharRequired && (
				<RenderMessage
					message={t('login.specialChar')}
					isValid={hasSpecial}
				/>
			)}
		</Box>
	)
}
