import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'ui-auth-app/modules/App/duc'
import { AuthDuc } from 'ui-auth-app/modules/Auth/duc'
import { isEmptyObject } from 'ui-auth-app/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DibizIconMobile from 'ui-lib/icons/dibiz-logo.svg'
import { Button } from 'ui-lib/components/Button'
import { Label, P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Input } from 'ui-lib/components/Input'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { LinkBlock } from 'ui-auth-app/modules/Auth/containers/Login'

const TwoStepVerification = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(AppDuc.selectors.location)
	const { query = {} } = location
	const { email } = query

	const validationSchema = yup.object().shape({
		otp: yup
			.string()
			.required()
			.min(6)
			.max(6),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			otp: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				AuthDuc.creators.verifyOtp(email, _values.otp, {
					setSubmitting,
				})
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.otp

	return (
		<Box
			justifyContent="center"
			alignItems="center"
			style={{ width: '100vw', height: '100vh', background: ' #1D285D' }}
		>
			<Box
				style={{
					background: theme.color.white,
					height: 'auto',
					width: isMobile ? 320 : 400,
				}}
				padding={20}
			>
				<form onSubmit={handleSubmit}>
					<Box>
						<Box center margin="8px 0">
							<IconWrapper size={50}>
								<Icon glyph={DibizIconMobile} />
							</IconWrapper>
						</Box>
						<Box style={{ width: '100%' }}>
							<Label
								style={{
									fontWeight: 'bold',
									fontSize: theme.fontSize.xl,
									color: theme.color.accent2,
									textAlign: 'center',
								}}
							>
								{t('login.two-step-verification')}
							</Label>
							<Spacer size={12} />
							<P style={{ fontSize: 16 }}>
								{t('login.two-step-verification-heading')}
							</P>
							<Spacer size={40} />

							<Label
								style={{
									fontWeight: 'bold',
									fontSize: theme.fontSize.xl,
									color: theme.color.accent2,
									textAlign: 'center',
								}}
							>
								{t('login.checkYourEmail')}
							</Label>
							<Spacer size={12} />
							<P style={{ fontSize: 16 }}>
								{t('login.two-step-verification-subheading')}
							</P>
							<Spacer size={40} />

							<Input
								value={values.otp}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.otp && errors.otp}
								name="otp"
								type="text"
								extendStyles={{
									background: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow: `0px 2px 8px ${theme.color.blue6}`,
									border: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
						</Box>

						<Spacer size={12} />

						<Button
							isLoading={isSubmitting}
							disabled={disableCTA}
							type="submit"
							primary
							label={t('common.submit')}
						/>
					</Box>
					<Spacer size={12} />
					<Box center>
						<LinkBlock
							style={{
								color: theme.color.blue4,
								fontSize: theme.fontSize.xl,
							}}
							onClick={() =>
								dispatch(AuthDuc.creators.initiateOTP(email))
							}
						>
							{t('login.resendOTP')}
						</LinkBlock>
					</Box>
				</form>
			</Box>
		</Box>
	)
}

export default TwoStepVerification
